import PropTypes from "prop-types";
import styles from "./BrandNav.module.scss";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo-white.svg";
import { Link } from "react-router-dom";

const BrandNav = props => {
  const { variant, className } = props;
  return (
    <div
      className={`${styles.BrandNav} ${
        styles[`BrandNav__${variant}`]
      } ${className}`}
    >
      <div className="nvam-logo-white">
        <Link to={"/"}>
          <Logo />
        </Link>
      </div>
    </div>
  );
};

BrandNav.defaultProps = {
  variant: "default",
  className: "",
};

BrandNav.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default BrandNav;
