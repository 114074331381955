// import { chooseEventIcon } from "components/calendar/ViewEvent/ViewEvent";
import PropTypes from "prop-types";
// import { useEffect, useState } from "react";
import styles from "./MiniViewEvent.module.scss";
import { ReactComponent as TrashCan } from "assets/icons/trash-can.svg";
import { ReactComponent as Edit } from "assets/icons/pen.svg";
import { ReactComponent as Notes } from "assets/icons/notes.svg";
import { ReactComponent as Clock } from "assets/icons/clock-black.svg";
import { ReactComponent as Location } from "assets/icons/location.svg";
import { ReactComponent as Telephone } from "assets/icons/telephone.svg";
import { ReactComponent as Services } from "assets/icons/services_performing.svg";
import { ReactComponent as Equipment } from "assets/icons/equipment_white.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as CtaArrow } from "assets/icons/cta-arrow.svg";
import icons from "helpers/icons";

import { format } from "date-fns";
import Button from "components/utils/Button";
import { useLocation, useNavigate } from "react-router-dom";

const MiniViewEvent = props => {
  const { variant, className, event } = props;

  const navigate = useNavigate();

  const location = useLocation();

  const isNewEstimate =
    (location.pathname.includes("/new-estimate") &&
      event.temp_id) ||
    location.pathname.includes("/calendar");

  const chooseEventIcon = item => {
    if (item.event_types_id === 1) {
      return icons.Travel;
    } else if (item.event_types_id === 2) {
      return icons.Meeting;
    } else if (item.event_types_id === 3) {
      return icons.Block;
    } else if (item.event_types_id === 4) {
      return icons.Time_off;
    } else if (item.event_types_id === 5) {
      return icons.Crew_time;
    } else if (item.event_types_id === 6) {
      return icons.Equipment;
    } else if (item.event_types_id === 9) {
      return icons.Training;
    } else if (item.event_types_id === 10) {
      return icons.Holiday;
    } else if (
      item.event_types_id === 8 ||
      item.event_types_id === 14
    ) {
      return icons.Tree;
    } else if (item.event_types_id > 6 && !item.job) {
      return icons.Estimate;
    } else if (item.event_types_id === 7) {
      return icons.Estimate;
    }
  };

  const renderServicesPerforming = arr => {
    return arr.map((item, index) => (
      <li key={index}>{item.label}</li>
    ));
  };

  const renderEquipment = () => {
    return event?.equipments.map((item, index) => (
      <li key={index}>{item.name}</li>
    ));
  };

  const handleEdit = () => {
    // if (event.id) {
    //   props.onEdit(event);
    // } else {
    //   props.onEdit({ ...event, mode: "EDIT" });
    // }
    props.onEdit({ ...event, mode: "EDIT" });
  };

  const handleDelete = () => {
    props.onDelete(event);
  };

  const handleOpenJobClick = () => {
    navigate(`/job/${event.job.id}`);
  };

  return (
    <div
      className={`${styles.MiniViewEvent} ${
        styles[`MiniViewEvent__${variant}`]
      } ${className}`}
    >
      <div className="event-header">
        <div className="header-left">
          <span>{chooseEventIcon(event)}</span>
          <span className="event-title">
            {event?.title}
          </span>
        </div>
        <div
          className="header-right"
          onClick={props.onClose}
        >
          <Close />
        </div>
      </div>

      <div className="event-body">
        {event.id && (
          <div className="event-row">
            <span
              style={{
                backgroundColor: `${
                  event?.user?.calendar_color || ""
                }`,
              }}
              className="calendar-color"
            ></span>
            <div className="event-text">
              <span>
                {" "}
                {event?.user?.calendar_name || ""}
              </span>
            </div>
          </div>
        )}

        <div className="event-time event-row">
          <span className="event-icon">
            <Clock />
          </span>
          <div className="event-text">
            <span>
              {format(
                event?.start || new Date(),
                "EEEE, d LLLL yyyy"
              )}
            </span>
            <span className="dot-separator"> . </span>
            <span>
              {format(event?.start || new Date(), "h:mm a")}
            </span>
            <span> - </span>
            <span>
              {format(event?.end || new Date(), "h:mm a")}
            </span>
          </div>
        </div>

        {!!event?.job?.admin_notes && (
          <div className="event-row">
            <span className="event-icon">
              <Notes />
            </span>
            <div className="event-text">
              <span>Client Requested Service:</span>
              <p>{event?.job?.admin_notes}</p>
            </div>
          </div>
        )}

        {/* ADDRESS */}

        {!event?.job ? (
          ""
        ) : (
          <div className="event-row">
            <span className="event-icon">
              <Location />
            </span>

            <div className="event-text">
              <span>
                {" "}
                {event?.job?.client?.address?.name}
              </span>
            </div>
          </div>
        )}

        {/* PHONE NUMBER */}

        {!event?.job ? (
          ""
        ) : (
          <div className="event-row">
            <span className="event-icon">
              <Telephone />
            </span>
            <div className="event-text">
              <span> {event?.job?.client?.phone}</span>
            </div>
          </div>
        )}

        {/* SERVICES PERFORMING */}

        {!event?.job ? (
          ""
        ) : (
          <div className="event-row">
            <span className="event-icon">
              <Services />
            </span>
            <div className="event-text">
              <span>Services performing</span>
              <ul>
                {renderServicesPerforming(
                  event?.job?.services
                )}
              </ul>
            </div>
          </div>
        )}

        {/* EQUIPMENT LISTING */}

        {event?.equipments?.length ? (
          <div className="event-row">
            <span className="event-icon">
              <Equipment />
            </span>
            <div className="event-text">
              <span>Equipment</span>
              <ul>{renderEquipment()}</ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {(event.event_types_id === 8 && event.id) ||
      event.event_types_id === 14 ? (
        <div
          className="cta-action"
          onClick={handleOpenJobClick}
        >
          <Button className="btn">
            <span>Open Job</span>
            <span className="cta-icon">
              <CtaArrow />
            </span>
          </Button>
        </div>
      ) : isNewEstimate ? (
        <div className="footer">
          <button className="action" onClick={handleEdit}>
            <Edit />
            <span>Edit</span>
          </button>

          <button className="action" onClick={handleDelete}>
            <TrashCan />
            <span>Delete</span>
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

MiniViewEvent.defaultProps = {
  variant: "default",
  className: "",
};

MiniViewEvent.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  event: PropTypes.object,
  eventIcon: PropTypes.element,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default MiniViewEvent;
