import PropTypes from "prop-types";
import styles from "./CtaLink.module.scss";
import React from "react";
const CtaLink = React.forwardRef((props, ref) => {
  const { href, onClick, title } = props;
  return (
    <a
      href={href}
      onClick={onClick}
      title={title}
      className={`${styles.CtaLink} ${props.className}`}
      ref={ref}
    >
      {props.children}
    </a>
  );
});

CtaLink.displayName = "CtaLink";

CtaLink.defaultProps = {
  className: "",
};

CtaLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  title: PropTypes.string,
};

export default CtaLink;
