import { createAsyncThunk } from "@reduxjs/toolkit";
import * as systemAPI from "api/system";

export const getConfig = createAsyncThunk(
  "system/config",
  async data => {
    const res = await systemAPI.getConfig(data);
    return res;
  }
);

export const search = createAsyncThunk(
  "system/search",
  async data => {
    const res = await systemAPI.search(data);
    return res;
  }
);
