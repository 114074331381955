import PropTypes from "prop-types";
import styles from "./SectionLoader.module.scss";
import Spinner from "components/utils/Spinner";

const SectionLoader = props => {
  return (
    <div
      className={`${styles.SectionLoader} ${props.className}`}
    >
      <Spinner />
    </div>
  );
};

SectionLoader.defaultProps = {
  className: "",
};

SectionLoader.propTypes = {
  className: PropTypes.string,
};

export default SectionLoader;
