import api from "utils/api";

export const login = (data, options) => {
  return api({
    url: "/v1/auth/login",
    method: "POST",
    data,
    ...options,
  });
};

export const register = (data, options) => {
  return api({
    url: "/v1/auth/register/NMNJ2eA8KCW0LjuFno0f",
    method: "POST",
    data,
    ...options,
  });
};

export const forgotPassword = (data, options) => {
  return api({
    url: "/v1/auth/forgot-password",
    method: "POST",
    data,
    ...options,
  });
};

export const validateToken = (data, options) => {
  return api({
    url: "/v1/auth/validate-token",
    method: "POST",
    data,
    ...options,
  });
};

export const setPassword = (data, options) => {
  return api({
    url: "/v1/auth/set-password",
    method: "POST",
    data,
    ...options,
  });
};

export const resetPassword = (data, options) => {
  return api({
    url: "/v1/auth/reset-password",
    method: "PATCH",
    data,
    ...options,
  });
};

export const session = options => {
  return api({
    url: "/v1/auth/session",
    method: "GET",
    ...options,
  });
};

export const logout = options => {
  return api({
    url: "/v1/auth/logout",
    method: "DELETE",
    ...options,
  });
};
