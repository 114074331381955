import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

export const isDev = () => {
  return process.env.NODE_ENV === "development";
};

export const sysDate = (date, format = "MM-DD-YYYY") => {
  switch (format) {
    case "long":
      return dayjs(date).format("ddd MMM Do, YYYY hh:mm A");
    case "long-date":
      return dayjs(date).format("ddd MMM Do, YYYY");
    default:
      return dayjs(date).format(format);
  }
};

export const PHONE_REGEX = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
export const NAME_REGEX = /^[A-Za-z]*$/;

export const sysPhone = phone => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  // 576-456-7890
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return phone;
};

export const cloudAssetPath = path => {
  return `${process.env.REACT_APP_DO_SPACES_HOST}/${path}`;
};

export const setFormErrors = (errors = {}, form) => {
  if (!Object.keys(errors).length) return;
  Object.keys(errors).forEach(key => {
    form.setError(key, {
      type: "manual",
      message: errors[key],
    });
  });
};

export const toSerializable = obj =>
  JSON.parse(JSON.stringify(obj));
