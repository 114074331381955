import { createAsyncThunk } from "@reduxjs/toolkit";
import * as clientAPI from "api/client";

export const create = createAsyncThunk(
  "client/create",
  async data => {
    const res = await clientAPI.create(data);
    return res;
  }
);

export const get = createAsyncThunk(
  "client/get",
  async data => {
    const res = await clientAPI.get(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "client/update",
  async data => {
    const res = await clientAPI.update(data);
    return res;
  }
);

export const list = createAsyncThunk(
  "client/list",
  async data => {
    const res = await clientAPI.list(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "client/remove",
  async data => {
    const res = await clientAPI.remove(data);
    return res;
  }
);

export const createNote = createAsyncThunk(
  "client/createNote",
  async data => {
    const res = await clientAPI.createNote(data);
    return res;
  }
);

export const updateNote = createAsyncThunk(
  "client/updateNote",
  async data => {
    const res = await clientAPI.updateNote(data);
    return res;
  }
);

export const listNote = createAsyncThunk(
  "client/listNote",
  async data => {
    const res = await clientAPI.listNote(data);
    return res;
  }
);

export const removeNote = createAsyncThunk(
  "client/removeNote",
  async data => {
    const res = await clientAPI.removeNote(data);
    return res;
  }
);

export const listProperty = createAsyncThunk(
  "client/listNote",
  async data => {
    const res = await clientAPI.listProperty(data);
    return res;
  }
);
