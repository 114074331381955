import PropTypes from "prop-types";
import styles from "./EventDatePicker.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";

const EventDatePicker = React.forwardRef((props, ref) => {
  const {
    variant,
    className,
    name,
    value,
    onChange,
    onBlur,
  } = props;

  const handleChange = date => {
    // debugger
    !!onChange &&
      onChange({
        target: {
          name: name,
          value: date,
        },
      });
  };

  return (
    <div
      className={`${styles.EventDatePicker} ${
        styles[`EventDatePicker__${variant}`]
      } ${className}`}
    >
      <DatePicker
        selected={value}
        onChange={handleChange}
        dateFormat="EEEE, MMM d, yyyy"
        id={name}
        name={name}
        value={value}
        ref={ref}
        onBlur={onBlur}
      />
    </div>
  );
});

EventDatePicker.displayName = "EventDatePicker";

EventDatePicker.defaultProps = {
  variant: "default",
  className: "",
  type: "text",
  errors: [],
  onChange: () => {},
};

EventDatePicker.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};

export default EventDatePicker;
