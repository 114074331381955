import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const pinSlice = createSlice({
  name: "pin",
  initialState: {
    all: [],
    selected: {},
    types: [],
    common_notes: [],
    hazards: [],
    tree_diameters: [],
    tree_healths: [],
    tree_heights: [],
    loading: false,
    prevJobs: [],
  },
  extraReducers: {
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const properties = action.payload?.data || [];
      state.all = properties;
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    [actions.get.pending]: state => {
      state.loading = true;
    },
    [actions.get.fulfilled]: (state, action) => {
      const { pin } = action.payload?.data || {};
      state.selected = pin || {};
      state.loading = false;
    },
    [actions.get.rejected]: state => {
      state.loading = false;
    },
    [actions.types.pending]: state => {
      state.loading = true;
    },
    [actions.types.fulfilled]: (state, action) => {
      const payload = action.payload?.data || {};
      state.types = payload?.pin_types?.data || [];
      state.common_notes =
        payload?.common_notes?.data || [];
      state.hazards = payload?.hazards?.data || [];
      state.tree_diameters =
        payload?.tree_diameters?.data || [];
      state.tree_healths = payload?.tree_health?.data || [];
      state.tree_heights =
        payload?.tree_heights?.data || [];
      state.loading = false;
    },
    [actions.types.rejected]: state => {
      state.loading = false;
    },
    [actions.typeSettings.pending]: state => {
      state.loading = true;
    },
    [actions.typeSettings.fulfilled]: (state, action) => {
      state.typeSettings = action.payload?.data || {};
      state.loading = false;
    },
    [actions.typeSettings.rejected]: state => {
      state.loading = false;
    },
    [actions.getPrevJobs.pending]: state => {
      state.loading = true;
    },
    [actions.getPrevJobs.fulfilled]: (state, action) => {
      state.prevJobs = action.payload?.data || {};
      state.loading = false;
    },
    [actions.getPrevJobs.rejected]: state => {
      state.loading = false;
    },
  },
});

export const pinActions = pinSlice.actions;

export default pinSlice.reducer;
