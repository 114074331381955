import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    all: [],
    authUser: {},
    selected: {},
    loading: false,
    notificationsRecords: 0,
    crewList: [],
    notifications: {
      loading: false,
      data: [],
      unreadCount: 0,
      page: 0,
      records: 0,
    },
  },
  reducers: {
    createNotification(state, action) {
      const notification = action.payload;
      state.notifications = {
        ...state.notifications,
        data: [notification, ...state.notifications.data],
        unreadCount: state.notifications.unreadCount + 1,
      };
    },
    updateNotification(state, action) {
      const notification = action.payload;
      state.notifications = {
        ...state.notifications,
        data: state.notifications.data.map(n =>
          n.id === notification.id ? notification : n
        ),
      };
    },
  },
  extraReducers: {
    [actions.profile.pending]: state => {
      state.loading = true;
    },
    [actions.profile.fulfilled]: (state, action) => {
      const { user } = action.payload?.data || {};
      state.authUser = user;
      state.loading = false;
    },
    [actions.profile.rejected]: state => {
      state.loading = false;
    },
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const users = action.payload?.data || [];
      state.all = users;
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    [actions.notifications.pending]: state => {
      state.notifications.loading = true;
    },
    [actions.notifications.fulfilled]: (state, action) => {
      const { meta } = action.payload;
      if (state.notifications.page === 0) {
        state.notifications = {
          ...state.notifications,
          data: [...(action.payload?.data || [])],
          unreadCount: meta.unread_notifications_count,
          records: meta.records,
          page: meta.page,
        };
      } else if (meta.page > state.notifications.page) {
        state.notifications = {
          ...state.notifications,
          data: [
            ...state.notifications.data,
            ...(action.payload?.data || []),
          ],
          unreadCount: meta.unread_notifications_count,
          records: meta.records,
          page: meta.page,
        };
      }
      state.notifications.loading = false;
    },
    [actions.notifications.rejected]: state => {
      state.notifications.loading = false;
    },
    [actions.notificationsCount.pending]: state => {
      state.notifications.loading = true;
    },
    [actions.notificationsCount.fulfilled]: (
      state,
      action
    ) => {
      const { unread_notification_count } =
        action.payload?.data || {};
      state.notifications.unreadCount =
        unread_notification_count;
      state.notifications.loading = false;
    },
    [actions.notificationsCount.rejected]: state => {
      state.notifications.loading = false;
    },
    [actions.markAsRead.fulfilled]: (state, action) => {
      state.notifications.unreadCount =
        action.payload?.meta.records;
      state.loading = false;
    },
    [actions.markAsUnRead.fulfilled]: (state, action) => {
      state.notifications.unreadCount =
        action.payload?.meta.records;
      state.loading = false;
    },
    [actions.markAllRead.fulfilled]: state => {
      state.notifications = {
        ...state.notifications,
        unreadCount: 0,
        data: state.notifications.data.map(n => {
          n.read_at = new Date().toISOString();
          return n;
        }),
      };
    },
    [actions.getCrew.pending]: state => {
      state.loading = true;
    },
    [actions.getCrew.fulfilled]: (state, action) => {
      const { users } = action.payload?.data || [];
      state.crewList = users;
      state.loading = false;
    },
    [actions.getCrew.rejected]: state => {
      state.loading = false;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
