import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const propertySlice = createSlice({
  name: "property",
  initialState: {
    all: [],
    selected: {},
    notes: [],
    pins: [],
    loading: false,
  },
  extraReducers: {
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const properties = action.payload?.data || [];
      state.all = properties;
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    [actions.get.pending]: state => {
      state.loading = true;
    },
    [actions.get.fulfilled]: (state, action) => {
      const { property } = action.payload?.data || {};
      state.selected = property || {};
      state.loading = false;
    },
    [actions.get.rejected]: state => {
      state.loading = false;
    },
    [actions.listPin.pending]: state => {
      state.loading = true;
    },
    [actions.listPin.fulfilled]: (state, action) => {
      const pins = action.payload?.data || [];
      state.pins = pins;
      state.loading = false;
    },
    [actions.listPin.rejected]: state => {
      state.loading = false;
    },

    [actions.getJobProperty.pending]: state => {
      state.loading = true;
    },
    [actions.getJobProperty.fulfilled]: (state, action) => {
      const { property } = action.payload?.data || {};
      state.selected = property || {};
      state.loading = false;
    },
    [actions.getJobProperty.rejected]: state => {
      state.loading = false;
    },
  },
});

export const propertyActions = propertySlice.actions;

export default propertySlice.reducer;
