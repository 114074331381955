import { createAsyncThunk } from "@reduxjs/toolkit";
import * as dashboardApi from "api/dashboard";

export const getEvents = createAsyncThunk(
  "dashboard/getEvents",
  async data => {
    const res = await dashboardApi.getEvents(data);
    return res;
  }
);

export const getActiveJobs = createAsyncThunk(
  "dashboard/getActiveJobs",
  async data => {
    const res = await dashboardApi.getActiveJobs(data);
    return res;
  }
);

export const getSales = createAsyncThunk(
  "dashboard/getSales",
  async data => {
    const res = await dashboardApi.getSales(data);
    return res;
  }
);

export const getTrendsData = createAsyncThunk(
  "event/create",
  async data => {
    const res = await dashboardApi.getTrendsData(data);
    return res;
  }
);
