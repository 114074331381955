import TextInput from "components/utils/TextInput";
import styles from "./Login.module.scss";
import Button from "components/utils/Button/Button";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as authActions from "store/auth/actions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const loginSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

function Login() {
  const dispatch = useDispatch();
  const actions = bindActionCreators(authActions, dispatch);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async data => {
    setLoading(true);
    const res = await actions.login(data).unwrap();
    setLoading(false);
    if (res.success) {
      navigate("/");
    } else {
      !!res.errors.email &&
        setError("email", {
          type: "manual",
          message: res.errors.email,
        });
      !!res.errors.password &&
        setError("password", {
          type: "manual",
          message: res.errors.password,
        });
    }
  };

  return (
    <div className={`${styles.Login} login`}>
      <div className="login__form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, onBlur, value, ref, name },
            }) => (
              <TextInput
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                placeholder="Email"
                className="form-group"
                errors={[errors?.email?.message]}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({
              field: { onChange, onBlur, value, ref, name },
            }) => (
              <TextInput
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                type="password"
                placeholder="Password"
                className="form-group"
                errors={[errors?.password?.message]}
              />
            )}
          />

          <Button
            className="btn btn--md form-group"
            type="submit"
            loading={loading}
          >
            Login
          </Button>
        </form>
      </div>
      <Link to="/forgot-password" title="Forgot Password">
        Forgot password?
      </Link>
    </div>
  );
}

export default Login;
