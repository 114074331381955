import PropTypes from "prop-types";
import styles from "./Spinner.module.scss";
import { ReactComponent as LoaderIcon } from "assets/icons/loader.svg";
import "./animation.scss";

const Spinner = props => {
  return (
    <div className={`${styles.Spinner} ${props.className}`}>
      <LoaderIcon />
    </div>
  );
};

Spinner.defaultProps = {
  className: "",
};

Spinner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Spinner;
