// import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toast.scss";

const Toast = () => {
  return (
    <ToastContainer
      position="bottom-left"
      hideProgressBar={true}
      closeOnClick
      theme="dark"
      icon={false}
    />
  );
};

Toast.defaultProps = {};

Toast.propTypes = {};

export default Toast;
