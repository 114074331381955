class Job {
  constructor(job) {
    this.id = job?.id;
    this.properties_id = job?.properties_id;
    this.admin_notes = job?.admin_notes;
    this.clients_id = job?.clients_id;
    this.estimator_id = job?.estimator_id;
    this.estimator = job?.estimator;
    this.estimate_time_type = job?.estimate_time_type;
    this.estimate_date = job?.estimate_date;
    this.estimate_start_time = job?.estimate_start_time;
    this.estimate_end_time = job?.estimate_end_time;
    this.date_created = job?.date_created;
    this.events = job?.events || [];
    // this.divisions_id = job.divisions_id;
    this.status = job?.status;
    this.client = job?.client;
    // this.client_address = job.client_address;
    this.permissions = job?.permissions || [];
    this.property_addresses = job?.property_addresses;
    this.return_required = job?.return_required;
    this.permissions = job?.permissions || [];
    this.job_status = job?.job_status || {};
    this.property_details = job?.property_details || [];
    this.pins = job?.pins || [];
    this.task = job?.task || [];
    this.tasks = job?.tasks || [];
    this.images = job?.images || [];
    this.list_jobs_id = job?.list_jobs_id;
    this.notes = job?.notes;
    this.pin_services = job?.pin_services;
    this.pins_id = job?.pins_id;
    this.comments = job?.comments;
    this.job_activity = job?.job_activity;
    this.ground_hours = job?.ground_hours;
    this.ground_minutes = job?.ground_minutes;
    this.climbing_hours = job?.climbing_hours;
    this.climbing_minutes = job?.climbing_minutes;
    this.unique_id = job?.unique_id;
    this.estimated_time_to_complete =
      job?.estimated_time_to_complete;
    this.return_notes = job?.return_notes;
    this.job_estimate = job?.job_estimate;
    this.return_status = job?.return_status;
    this.estimated_time_to_complete_minutes =
      job?.estimated_time_to_complete_minutes;
    this.estimated_time_to_complete_hours =
      job?.estimated_time_to_complete_hours;
  }
  fullName() {
    return `${this.client?.first_name} ${this.client?.last_name}`;
  }
  canEstimate() {
    const hasAtLeastOneTask = this.property_details.some(
      property =>
        property.line_item_details.some(
          item => item.tasks.length
        )
    );
    return hasAtLeastOneTask;
  }
}

export default Job;
