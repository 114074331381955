import PropTypes from "prop-types";

import Client from "./Client";
import State from "./State";

export const PropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  division: PropTypes.string,
  address: PropTypes.string,
});

class Property {
  constructor(property) {
    this.id = property.id;
    this.property_name = property.property_name;
    this.business_name = property.business_name;

    this.lat = property.lat;
    this.lng = property.lng;

    this.divisions_id = property.divisions_id;
    this.division = property.division;

    this.pin_details = property.pin_details;

    this.date_created = property.date_created;
    this.address = property.address;
    this.zip = property.zip;
    this.is_same_as_billing_addr =
      property.is_same_as_billing_addr;

    this.states_id = property.states_id;
    this.state = property.state
      ? new State(property.state)
      : new State();

    this.client = property.client
      ? new Client(property.client)
      : new Client({});

    this.pin_count = property.pin_count;
    this.pins = property.pins || [];
  }
  getAddress() {
    return this.address.name;
  }
  fullName() {
    return `${this.client.first_name} ${this.client.last_name}`;
  }
}

export default Property;
