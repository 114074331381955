const { createSelector } = require("@reduxjs/toolkit");

const systemState = state => state.system;

export const allDivisions = createSelector(
  systemState,
  system => system.divisions
);

export const allUserStatus = createSelector(
  systemState,
  system => system.user_status
);

export const allStates = createSelector(
  systemState,
  system => system.states
);

export const allRoles = createSelector(
  systemState,
  system => system.roles
);

export const allCrew = createSelector(
  systemState,
  system => system.crew
);
export const allStatus = createSelector(
  systemState,
  system => system.status
);

export const allPinService = createSelector(
  systemState,
  system => system.service
);

export const allEquipments = createSelector(
  systemState,
  system => system.equipments
);

export const companyDetails = createSelector(
  systemState,
  system => system.company_details
);

export const estimateStatuses = createSelector(
  systemState,
  system => system.job_estimate_status
);
