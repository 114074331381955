import PropTypes from "prop-types";
import React from "react";
import styles from "./SearchInput.module.scss";
import { ReactComponent as Magnifier } from "assets/icons/magnifier.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";

const SearchInput = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    placeholder,
    value,
    onChange,
    onBlur,
  } = props;

  const removeKeyword = () => {
    props.resetValue();
  };
  return (
    <div
      className={`${styles.SearchInput} ${props.className}`}
    >
      <Magnifier className="search-icon" />
      <input
        className="form-control"
        id={name}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        ref={ref}
        maxLength="50"
        onChange={onChange}
        onBlur={onBlur}
      />
      <Close
        className="close-btn"
        onClick={removeKeyword}
      />
    </div>
  );
});

SearchInput.displayName = "SearchInput";

SearchInput.defaultProps = {
  className: "",
  type: "text",
  errors: [],
  onChange: () => {},
};

SearchInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  resetValue: PropTypes.func,
};

export default SearchInput;
