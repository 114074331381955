import PropTypes from "prop-types";
import React from "react";
import styles from "./SelectInput.module.scss";
import ChevronDown from "assets/icons/chevron-down.svg";

export const Option = props => {
  const { value, selected } = props;
  return (
    <option value={value} selected={selected}>
      {props.children}
    </option>
  );
};

Option.defaultProps = {
  value: "",
};

Option.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
};

/**
 * SelectInput component
 * Use above Option component to create options
 */

const SelectInput = React.forwardRef((props, ref) => {
  const {
    name,
    type,
    label,
    placeholder,
    value,
    errors,
    onChange,
    onBlur,
    variant,
  } = props;

  return (
    <div
      className={`${styles.SelectInput} ${
        props.className
      } ${styles[`SelectInput__${variant}`]}`}
    >
      {label ? (
        <label htmlFor={name} className="form-label">
          {label}
        </label>
      ) : null}
      <div className="input-wrap">
        <select
          className="form-control"
          id={name}
          name={name}
          type={type}
          value={value}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          data-selected={!!value}
          style={{
            backgroundImage: `url(${ChevronDown})`,
          }}
        >
          {props.children}
        </select>
        {!!placeholder && (
          <label className="placeholder">
            {placeholder}
          </label>
        )}
      </div>
      {errors && errors.length > 0 && (
        <div className="form-errors">
          {errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
    </div>
  );
});

SelectInput.displayName = "SelectInput";

SelectInput.defaultProps = {
  className: "",
  type: "text",
  errors: [],
  onChange: () => {},
  variant: "default",
};

SelectInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  variant: PropTypes.string,
};

export default SelectInput;
