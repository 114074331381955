import PropTypes from "prop-types";
import styles from "./PreNavbar.module.scss";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo-white.svg";
import Button from "components/utils/Button/Button";
import Search from "components/system/Search";
import { Link } from "react-router-dom";
import { ReactComponent as Magnifier } from "assets/icons/magnifier-white.svg";
// import { ReactComponent as Close } from "assets/icons/close.svg";
// import SearchInput from "components/utils/SearchInput";
import NavNotifications from "components/utils/NavNotifications";
import { useSelector } from "react-redux";
import { authUser } from "store/user/selectors";
import CtaLink from "components/utils/CtaLink";
import { useState } from "react";

const PreNavbar = props => {
  const { minimal, onHamburgerClick } = props;

  const [toggleSearchbar, setToggleSearchbar] =
    useState(false);

  const user = useSelector(authUser);

  return (
    <nav
      className={`${styles.PreNavbar} ${props.className} navbar`}
    >
      <div className="container-fluid">
        <div
          className={`navbar__wrap ${
            !!toggleSearchbar && "show"
          }`}
        >
          {!minimal && (
            <>
              <div className="navbar__left">
                <div
                  className="navbar__search-icon"
                  onClick={() =>
                    setToggleSearchbar(!toggleSearchbar)
                  }
                >
                  <Magnifier />
                </div>
                <NavNotifications />
                {["admin", "scheduling-admin"].includes(
                  user.role?.slug
                ) && (
                  <a
                    href="/new-estimate"
                    target="_blank"
                    className="navbar__btn"
                  >
                    <Button className="btn">
                      New Estimate
                    </Button>
                  </a>
                )}
              </div>
              <div className="navbar__middle">
                <Search className="navbar__search" />
                <CtaLink
                  className="navbar__cta in-mob"
                  onClick={() =>
                    setToggleSearchbar(!toggleSearchbar)
                  }
                >
                  Cancel
                </CtaLink>
              </div>
            </>
          )}
          <div
            className={`navbar__right navbar__logo ${
              minimal ? "ms-auto navbar--minimal" : ""
            }`}
          >
            <div
              className="navbar__burger"
              onClick={onHamburgerClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
        </div>
        {/* <div className="navbar-mob">
          <div className="navbar-mob__left">
            <div className="burger-menu">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="nvam-logo-white">
              <Link to={"/"}>
                <Logo />
              </Link>
            </div>
          </div>
          <div className="navbar-mob__right">
            <div className="search-icon">
              <Magnifier />
            </div>
            <Button className="btn">New Job</Button>
          </div>
        </div> */}
        {/* Add .search-show and .search-hide to show and hide the search bar expansion  */}
        {/* <div className="navbar-mob__search search-hide">
          <div className="search-bar">
            <div className="search-input">
              <SearchInput placeholder="Search" />
              <Close className="close-btn" />
            </div>
            <span>Cancel</span>
          </div>
        </div> */}
      </div>
    </nav>
  );
};

PreNavbar.defaultProps = {
  className: "",
};

PreNavbar.propTypes = {
  className: PropTypes.string,
  minimal: PropTypes.bool,
  onHamburgerClick: PropTypes.func,
};

export default PreNavbar;
