import React, {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import Loader from "components/layouts/Loader";
import PreNavbar from "components/layouts/PreNavbar";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as _systemActions from "store/system/actions";
import * as _userActions from "store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { authUser } from "store/user/selectors";

const SubLayout = props => {
  const [loading, setLoading] = useState(true);

  const user = useSelector(state => authUser(state));

  const dispatch = useDispatch();

  const systemActions = useMemo(
    () => bindActionCreators(_systemActions, dispatch),
    [dispatch]
  );

  const userActions = useMemo(
    () => bindActionCreators(_userActions, dispatch),
    [dispatch]
  );

  const init = async () => {
    setLoading(true);
    const initSequence = [systemActions.getConfig()];
    if (!user?.id) {
      initSequence.push(userActions.profile());
    }
    await Promise.all(initSequence);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <header>
        <PreNavbar minimal={true} />
      </header>
      <main>
        <Suspense fallback={<Loader />}>
          {loading ? <Loader /> : props.children}
        </Suspense>
      </main>
      <footer></footer>
    </>
  );
};

SubLayout.propTypes = {
  children: PropTypes.node,
};

export default SubLayout;
