/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import styles from "./WeekDatePicker.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as NextArrow } from "assets/icons/next-arrow.svg";
import { ReactComponent as PrevArrow } from "assets/icons/back-arrow.svg";
import { ReactComponent as ChevronDown } from "assets/icons/chevron-down.svg";
import { ReactComponent as Travel } from "assets/icons/directions-car-black.svg";
import { ReactComponent as Meetings } from "assets/icons/event-meetings-gray.svg";
import { ReactComponent as Block } from "assets/icons/event-block-gray.svg";
import { ReactComponent as TimeOff } from "assets/icons/event-time-off-gray.svg";
import { ReactComponent as CrewTime } from "assets/icons/event_crew_time_grey.svg";
import { ReactComponent as Equipment } from "assets/icons/event_equipment_grey.svg";
import { ReactComponent as Training } from "assets/icons/event-training-gray.svg";
import { ReactComponent as Holiday } from "assets/icons/event-holiday-gray.svg";

import { ReactComponent as Plus } from "assets/icons/plus.svg";
import OutsideClickHandler from "react-outside-click-handler";

import { forwardRef, useState } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";

import { useLocation } from "react-router-dom";

export const MonthPicker = forwardRef((props, ref) => {
  const { date, onClick, value } = props;

  return (
    <div
      className="week-picker__month"
      onClick={onClick}
      ref={ref}
    >
      <h2 className="sm">
        {format(new Date(date), "MMM yyyy")}
      </h2>
      <span className="chevron-down">
        <ChevronDown />
      </span>
    </div>
  );
});

MonthPicker.displayName = "MonthPicker";

const WeekDatePicker = props => {
  const { variant, className, date, datePicker } = props;

  const location = useLocation();

  const [currentMonth, setCurrentMonth] = useState(
    new Date()
  );
  const [currentWeek, setCurrentWeek] = useState(
    getWeek(currentMonth)
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );

  const [startDate, setStartDate] = useState(new Date());

  const [newEventPopper, setNewEventPopper] =
    useState(false);

  const handleNewEventSelect = (type, name) => {
    props.onAddNewEvent(type, name);
  };

  const changeWeekHandle = btnType => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
    if (btnType === "today") {
      setCurrentMonth(new Date());
      onDateClickHandle(new Date());
    }
  };

  const onDateClickHandle = day => {
    setSelectedDate(day);
    date(day);
  };

  const renderDays = () => {
    const dateFormat = "EEEEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col day" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return (
      <div className="week-picker__days row">{days}</div>
    );
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    const endDate = lastDayOfWeek(currentMonth, {
      weekStartsOn: 1,
    });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col date ${
              isSameDay(day, new Date())
                ? "date--current"
                : isSameDay(day, selectedDate)
                ? "date--selected"
                : ""
            }`}
            key={day}
            onClick={() => {
              const dayStr = format(
                cloneDay,
                "ccc dd MMM yy"
              );
              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="week-picker__dates row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="week-picker__body">{rows}</div>;
  };

  const handleDatePick = d => {
    setCurrentMonth(d);
    onDateClickHandle(d);
  };

  const handleNewEventClick = () => {
    if (location.pathname.includes("/job")) {
      props.setCrewEventModal(true);
      return;
    }
    setNewEventPopper(!newEventPopper);
  };

  return (
    <div
      className={`${styles.WeekDatePicker} ${
        styles[`WeekDatePicker__${variant}`]
      } ${className} week-picker`}
    >
      <div className="week-picker__header">
        <div className="week-picker__selector">
          <DatePicker
            selected={currentMonth}
            onChange={date => setStartDate(date)}
            onSelect={date => handleDatePick(date)}
            customInput={
              <MonthPicker date={currentMonth} />
            }
          />
        </div>
        <div className="week-picker__nav">
          <div className="week-picker__add-events">
            <Plus
              className="add"
              onClick={handleNewEventClick}
            />

            <OutsideClickHandler
              onOutsideClick={() =>
                setNewEventPopper(false)
              }
            >
              <div
                className={`week-picker__menu ${
                  newEventPopper ? "show" : ""
                }`}
              >
                <ul>
                  <li
                    onClick={() =>
                      handleNewEventSelect(1, "Travel")
                    }
                  >
                    <span className="icon">
                      <Travel />
                    </span>
                    <span className="item">Travel</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(2, "Meeting")
                    }
                  >
                    <span className="icon">
                      <Meetings />
                    </span>
                    <span className="item">Meeting</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(3, "Block")
                    }
                  >
                    <span className="icon">
                      <Block />
                    </span>
                    <span className="item">Block</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(4, "Time off")
                    }
                  >
                    <span className="icon">
                      <TimeOff />
                    </span>
                    <span className="item">Time off</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(5, "Crew time")
                    }
                  >
                    <span className="icon">
                      <CrewTime />
                    </span>
                    <span className="item">Crew time</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(6, "Equipment")
                    }
                  >
                    <span className="icon">
                      <Equipment />
                    </span>
                    <span className="item">Equipment</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(9, "Training")
                    }
                  >
                    <span className="icon">
                      <Training />
                    </span>
                    <span className="item">Training</span>
                  </li>
                  <li
                    onClick={() =>
                      handleNewEventSelect(10, "Holiday")
                    }
                  >
                    <span className="icon">
                      <Holiday />
                    </span>
                    <span className="item">Holiday</span>
                  </li>
                </ul>
              </div>
            </OutsideClickHandler>
          </div>
          <div className="week-picker__arrow prev">
            <div
              className="icon"
              onClick={() => changeWeekHandle("prev")}
            >
              <PrevArrow />
            </div>
          </div>

          <div
            className="week-picker__arrow next"
            onClick={() => changeWeekHandle("next")}
          >
            <div className="icon">
              <NextArrow />
            </div>
          </div>
          <div
            className="week-picker__btn"
            title="Go to Today"
            onClick={() => changeWeekHandle("today")}
          >
            Today
          </div>
        </div>
      </div>

      {renderDays()}
      {renderCells()}
    </div>
  );
};

WeekDatePicker.defaultProps = {
  variant: "default",
  className: "",
};

WeekDatePicker.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.func,
  datePicker: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  setCrewEventModal: PropTypes.func,
};

MonthPicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  date: PropTypes.object,
};

export default WeekDatePicker;
