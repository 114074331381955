import api from "utils/api";

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property",
    method: "POST",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/properties",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const listPin = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:id/pins",
    method: "GET",
    data,
    ...options,
  });
};
export const duplicatePin = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:id/pin/:pinId/duplicate",
    method: "POST",
    data,
    ...options,
  });
};

export const getJobProperty = (config = {}) => {
  const { data, options } = config;

  return api({
    url: "/v1/job/:jobId/property/:propertyId",
    method: "GET",
    data,
    ...options,
  });
};
