import User from "models/User";

const { createSelector } = require("@reduxjs/toolkit");

const userState = state => state.user;

export const authUser = createSelector(
  userState,
  user => new User(user.authUser)
);

export const allUsers = createSelector(userState, user =>
  user.all.map(_user => new User(_user))
);

export const getCrew = createSelector(userState, crew =>
  crew.crewList.map(_crew => new User(_crew))
);

export const selectedUser = createSelector(
  userState,
  user => new User(user.selected)
);

export const notifications = createSelector(
  userState,
  user => user.notifications
);
