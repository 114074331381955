import PropTypes from "prop-types";
import styles from "./AdminNav.module.scss";
import { NavLink, useNavigate } from "react-router-dom";
import * as authActions from "store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { authUser } from "store/user/selectors";
import Popper from "components/utils/Popper";
import { ReactComponent as DownArrow } from "assets/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo-white.svg";
import Button from "components/utils/Button";

const AdminNav = props => {
  const { open, setNavOpen } = props;

  const dispatch = useDispatch();

  const actions = bindActionCreators(authActions, dispatch);

  const navigate = useNavigate();

  const location = useLocation();

  const user = useSelector(state => authUser(state));

  const [userTray, setUserTray] = useState(false);

  const [clientsActive, setClientsActive] = useState(false);
  const [propsActive, setPropsActive] = useState(false);

  const handleLogout = async () => {
    const res = await actions.logout().unwrap();
    setUserTray(false);
    if (res.success) {
      navigate("/login");
    }
  };

  const addActiveClass = () => {
    if (location.pathname.includes("/client")) {
      setPropsActive(false);
      setClientsActive(true);
    } else if (
      location.pathname.includes("/property") &&
      !location.pathname.includes("/job")
    ) {
      setClientsActive(false);
      setPropsActive(true);
    } else {
      setClientsActive(false);
      setPropsActive(false);
    }
  };

  useEffect(() => {
    addActiveClass();
  }, [location.pathname]);

  return (
    <nav
      className={`${styles.AdminNav} ${props.className} ${
        open ? "active" : ""
      }`}
    >
      <div
        className="overlay"
        onClick={() => {
          setNavOpen(false);
        }}
      ></div>
      <div className="nav__wrap">
        <div className="nav__logo">
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>
        <div className="nav">
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink
                className="nav__link"
                aria-current="page"
                onClick={() => {
                  setNavOpen(false);
                }}
                to="/"
              >
                Dashboard
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink
                className="nav__link"
                to="/calendar"
                onClick={() => {
                  setNavOpen(false);
                }}
              >
                Calendar
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink
                className="nav__link"
                to="/job"
                onClick={() => {
                  setNavOpen(false);
                }}
              >
                Jobs
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink
                className={`nav__link ${
                  clientsActive ? "active" : ""
                }`}
                to="/clients"
                onClick={() => {
                  setNavOpen(false);
                }}
              >
                Clients
              </NavLink>
            </li>

            <li className="nav__item">
              <NavLink
                className={`nav__link ${
                  propsActive ? "active" : ""
                }`}
                to="/properties"
                onClick={() => {
                  setNavOpen(false);
                }}
              >
                Properties
              </NavLink>
            </li>

            {!!user.hasPermission("manage-users") && (
              <li className="nav__item">
                <NavLink
                  className="nav__link"
                  to="/users"
                  onClick={() => {
                    setNavOpen(false);
                  }}
                >
                  Manage Users
                </NavLink>
              </li>
            )}
            {["admin", "scheduling-admin"].includes(
              user.role?.slug
            ) && (
              <li className="nav__item nav__btn in-mob">
                <Button
                  onClick={() => {
                    setNavOpen(false);
                    navigate("/new-estimate");
                  }}
                >
                  New Estimate
                </Button>
              </li>
            )}
            <li className="nav__item nav__tray in-mob">
              <div className="tray__content">
                <h3 title={user.fullName()}>
                  {user.fullName()}
                </h3>
                <p title={user.email}>{user.email}</p>
              </div>
              <a
                className="tray__cta"
                onClick={handleLogout}
              >
                Sign out
              </a>
            </li>
            <li className="nav__item ms-auto in-desktop">
              <Popper
                onOpen={() => setUserTray(true)}
                onClose={() => setUserTray(false)}
                open={userTray}
                offset={[-58, 32]}
                className="nav__popper"
                trigger={
                  <div className="nav__user">
                    <a className="nav__link">
                      {user.fullName()}
                    </a>
                    <DownArrow />
                  </div>
                }
              >
                <div className="nav__tray tray">
                  <div className="tray__content">
                    <h3 title={user.fullName()}>
                      {user.fullName()}
                    </h3>
                    <p title={user.email}>{user.email}</p>
                  </div>
                  <a
                    className="tray__cta"
                    onClick={handleLogout}
                  >
                    Sign out
                  </a>
                </div>
              </Popper>
            </li>
          </ul>
        </div>
      </div>
      {/* Add show or hide class to .nav-mob to show and hide the nav list menu  */}
      {/* <div className="nav-mob hide">
        <ul className="nav-mob__list">
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              aria-current="page"
              to="/"
            >
              Dashboard
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/calendar"
            >
              Calendar
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink className="nav-mob__link" to="/job">
              Jobs
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/clients"
            >
              Clients
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink
              className="nav-mob__link"
              to="/properties"
            >
              Properties
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <NavLink className="nav-mob__link" to="/users">
              Manage Users
            </NavLink>
          </li>
          <li className="nav-mob__item">
            <a
              href="#"
              className="nav-mob__link"
              onClick={handleLogout}
            >
              Logout
            </a>
          </li>
        </ul>
      </div> */}
    </nav>
  );
};

AdminNav.defaultProps = {
  className: "",
};

AdminNav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  setNavOpen: PropTypes.func,
};

export default AdminNav;
