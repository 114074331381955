import PropTypes from "prop-types";
import store from "store";

export const PropType = PropTypes.shape({
  id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
});

class Client {
  constructor(client) {
    this.id = client.id;
    this.hash = client.hash;
    this.first_name = client.first_name;
    this.last_name = client.last_name;
    this.business_name = client.business_name;
    this.email = client.email;
    this.phone = client.phone;
    this.divisions_id = client.divisions_id;
    this.status = client.status;
    this.date_created = client.date_created;
    this.lat = client.lat;
    this.lng = client.lng;
    this.address = client.address;
    this.zip = client.zip;
    this.states_id = client.states_id;
    this.total_sales = client.total_sales;
  }
  fullName() {
    return `${this.first_name} ${this.last_name}`;
  }
  getState() {
    const { states } = store.getState().system;
    const state = states.find(s => s.id === this.states_id);
    return state ? state.name : "";
  }
}

export default Client;
