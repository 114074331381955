import PropTypes from "prop-types";
import styles from "./OutsideClickHandler.module.scss";

import ReactOctOutsideClickHandler from "react-outside-click-handler";

const OutsideClickHandler = props => {
  const { variant, className } = props;
  return (
    <ReactOctOutsideClickHandler
      className={`${styles.OutsideClickHandler} ${
        styles[`OutsideClickHandler__${variant}`]
      } ${className}`}
      onOutsideClick={props.onOutsideClick}
    >
      {props.children}
    </ReactOctOutsideClickHandler>
  );
};

OutsideClickHandler.defaultProps = {
  variant: "default",
  className: "",
};

OutsideClickHandler.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  onOutsideClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default OutsideClickHandler;
