import PropTypes from "prop-types";

export const PropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

class State {
  constructor(state) {
    this.id = state?.id;
    this.name = state?.name;
  }
}

export default State;
