import React, { Suspense } from "react";
import PropTypes from "prop-types";
import styles from "./AuthLayout.module.scss";
import Loader from "components/layouts/Loader";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo.svg";

const AuthLayout = props => {
  return (
    <div className={`${styles.AuthLayout}`}>
      <header></header>
      <main>
        <Suspense fallback={<Loader />}>
          <div className="container">
            <div className="content-wrapper">
              <Logo className="nvam-logo" />
              {props.children}
            </div>
          </div>
        </Suspense>
      </main>
      <footer></footer>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
};

export default AuthLayout;
