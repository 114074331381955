import api from "utils/api";

export const getEvents = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/",
    method: "GET",
    data,
    ...options,
  });
};

export const getSales = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/dashboard/sales",
    method: "GET",
    data,
    ...options,
  });
};

export const getActiveJobs = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/dashboard/active-jobs",
    method: "GET",
    data,
    ...options,
  });
};

export const getTrendsData = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/dashboard/trends",
    method: "GET",
    data,
    ...options,
  });
};
