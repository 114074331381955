import styles from "./Loader.module.scss";
// import { ReactComponent as LoaderIcon } from "assets/icons/loader.svg";
import { ReactComponent as Logo } from "assets/icons/arbor-manage-logo.svg";
import Spinner from "components/utils/Spinner";
function Loader() {
  return (
    <div className={`${styles.Loader} loader`}>
      <Spinner className="loader__icon" />
      <Logo className="nvam-logo" />
    </div>
  );
}
export default Loader;
