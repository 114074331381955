import { configureStore } from "@reduxjs/toolkit";

import systemReducer from "./system";
import authReducer from "./auth";
import userReducer from "./user";
import clientReducer from "./client";
import propertyReducer from "./property";
import pinReducer from "./pin";
import calendarReducer from "./calendar";
import dashboardReducer from "./dashboard";
import jobReducer from "./job";

export default configureStore({
  reducer: {
    system: systemReducer,
    auth: authReducer,
    user: userReducer,
    client: clientReducer,
    property: propertyReducer,
    pin: pinReducer,
    calendar: calendarReducer,
    dashboard: dashboardReducer,
    job: jobReducer,
  },
});
