import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    allEvents: [],
    createdEvent: {},
    actionedEvent: {},
    estimators: [],
    crews: [],
    eventTypes: [],
    equipment: [],
    loading: false,
  },
  reducers: {
    updateEvent(state, action) {
      const {
        event_date,
        event_start_time,
        event_end_time,
      } = action.payload;

      state.allEvents = state.allEvents.map(event => {
        if (event.id == action.payload?.id) {
          event.event_date = event_date;
          event.event_start_time = event_start_time;
          event.event_end_time = event_end_time;
        }
        return event;
      });
    },
  },
  extraReducers: {
    //list all events
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const events = action.payload?.data || [];
      state.allEvents = events;
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    //get single event
    [actions.get.pending]: state => {
      state.loading = true;
    },
    [actions.get.fulfilled]: (state, action) => {
      const event = action.payload?.data || {};
      state.actionedEvent = event || {};
      state.loading = false;
    },
    [actions.get.rejected]: state => {
      state.loading = false;
    },
    //get calendar settings
    [actions.fetchSettings.pending]: state => {
      state.loading = true;
    },
    [actions.fetchSettings.fulfilled]: (state, action) => {
      state.estimators =
        action.payload?.data.estimators || [];
      state.eventTypes =
        action.payload?.data.event_types || [];
      state.crews = action.payload?.data.crew_leads;
      state.equipment =
        action.payload?.data.equipments || [];
      state.loading = false;
    },
    [actions.fetchSettings.rejected]: state => {
      state.loading = false;
    },
    //create event
    [actions.create.pending]: state => {
      state.loading = true;
    },
    [actions.create.fulfilled]: (state, action) => {
      state.actionedEvent = action.payload?.data || {};
      state.loading = false;
    },
    [actions.create.rejected]: state => {
      state.loading = false;
    },
    //update event
    [actions.update.pending]: state => {
      state.loading = true;
    },
    [actions.update.fulfilled]: (state, action) => {
      state.actionedEvent = action.payload?.data || {};
      state.loading = false;
    },
    [actions.update.rejected]: state => {
      state.loading = false;
    },
  },
});

export const calendarActions = calendarSlice.actions;

export default calendarSlice.reducer;
