import DashboardCalendar from "components/dashboard/DashboardCalendar";
import DashboardJobs from "components/dashboard/DashboardJobs";
import DashboardNotification from "components/dashboard/DashboardNotification";
import DashboardSales from "components/dashboard/DashboardSales";
import DashboardTrends from "components/dashboard/DashboardTrends";
import PropTypes from "prop-types";
import styles from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <div className={`${styles.Dashboard} dboard`}>
      <div className="dboard__col dboard__col--left">
        <DashboardCalendar />
      </div>
      <div className="dboard__col dboard__col--mid">
        <div className="dboard__row">
          <DashboardJobs />
        </div>
        <div className="dboard__row">
          <DashboardSales />
        </div>
        <div className="dboard__row">
          <DashboardTrends />
        </div>
      </div>
      <div className="dboard__col dboard__col--right">
        <DashboardNotification />
      </div>
    </div>
  );
};

Dashboard.defaultProps = {
  className: "",
};

Dashboard.propTypes = {
  className: PropTypes.string,
};

export default Dashboard;
