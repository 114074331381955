import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

/**
 * Fetching token from local storage on app start
 */
const token = localStorage.getItem("u_tok");

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    /**
     * Checking if token exists in local storage
     */
    isAuthenticated: token ? true : false,
    loading: false,
  },
  extraReducers: {
    // login
    [actions.login.pending]: state => {
      state.loading = true;
    },
    [actions.login.fulfilled]: (state, action) => {
      if (!action.payload?.success) {
        state.loading = false;
        return;
      }

      const { access_token } = action.payload?.data || {};

      /**
       * Setting token in local storage after login
       */
      localStorage.setItem("u_tok", access_token);
      state.isAuthenticated = true;
      state.loading = false;
    },
    [actions.login.rejected]: state => {
      state.loading = false;
    },
    // register
    [actions.register.pending]: state => {
      state.loading = true;
    },
    [actions.register.fulfilled]: (state, action) => {
      if (!action.payload?.success) {
        state.loading = false;
        return;
      }
    },
    [actions.register.rejected]: state => {
      state.loading = false;
    },
    // forgotPassword
    [actions.forgotPassword.pending]: state => {
      state.loading = true;
    },
    [actions.forgotPassword.fulfilled]: state => {
      state.loading = false;
    },
    [actions.forgotPassword.rejected]: state => {
      state.loading = false;
    },
    // logout
    [actions.logout.pending]: state => {
      state.loading = true;
    },
    [actions.logout.fulfilled]: state => {
      /**
       * Clearing local storage after logout
       */
      localStorage.clear();
      state.user = {};
      state.isAuthenticated = false;
      state.loading = false;
    },
    [actions.logout.rejected]: state => {
      state.loading = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
