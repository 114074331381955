import api from "utils/api";

export const getConfig = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/config/values",
    method: "GET",
    data,
    ...options,
  });
};

export const uploadImage = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/image/save",
    method: "POST",
    data,
    ...options,
  });
};

export const removeImage = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/image/delete",
    method: "DELETE",
    data,
    ...options,
  });
};

export const search = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/search/global",
    method: "GET",
    data,
    ...options,
  });
};
