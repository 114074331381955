import { createAsyncThunk } from "@reduxjs/toolkit";
import * as jobAPI from "api/job";

// export const profile = createAsyncThunk(
//   "job/profile",
//   async data => {
//     const res = await jobAPI.profile(data);
//     return res;
//   }
// );

// export const create = createAsyncThunk(
//   "job/create",
//   async data => {
//     const res = await jobAPI.create(data);
//     return res;
//   }
// );

export const get = createAsyncThunk(
  "job/get",
  async data => {
    const res = await jobAPI.get(data);
    return res;
  }
);
// export const get = createAsyncThunk(
//   "job/get",
//   async data => {
//     const res = await jobAPI.get(data);
//     return res;
//   }
// );

// export const update = createAsyncThunk(
//   "job/update",
//   async data => {
//     const res = await jobAPI.update(data);
//     return res;
//   }
// );

export const list = createAsyncThunk(
  "job/list",
  async data => {
    const res = await jobAPI.list(data);
    return res;
  }
);

export const create = createAsyncThunk(
  "job/create",
  async data => {
    const res = await jobAPI.create(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "job/update",
  async data => {
    const res = await jobAPI.update(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "job/remove",
  async data => {
    const res = await jobAPI.remove(data);
    res.meta = { ...res.meta, ...data.options.query };
    return res;
  }
);

export const addTask = createAsyncThunk(
  "job/addTask",
  async data => {
    const res = await jobAPI.addTask(data);
    return res;
  }
);

export const editTask = createAsyncThunk(
  "job/editTask",
  async data => {
    const res = await jobAPI.editTask(data);
    return res;
  }
);

export const getTask = createAsyncThunk(
  "job/getTask",
  async data => {
    const res = await jobAPI.getTask(data);
    return res;
  }
);

export const deleteTask = createAsyncThunk(
  "job/deleteTask",
  async data => {
    const res = await jobAPI.deleteTask(data);
    return res;
  }
);

export const addEstimate = createAsyncThunk(
  "job/addEstimate",
  async data => {
    const res = await jobAPI.addEstimate(data);
    return res;
  }
);

export const sentToClient = createAsyncThunk(
  "job/sentToClient",
  async data => {
    const res = await jobAPI.sentToClient(data);
    return res;
  }
);

export const addHourUpdate = createAsyncThunk(
  "job/addHourUpdate",
  async data => {
    const res = await jobAPI.addHourUpdate(data);
    return res;
  }
);

export const editHourUpdate = createAsyncThunk(
  "job/editHourUpdate",
  async data => {
    const res = await jobAPI.editHourUpdate(data);
    return res;
  }
);

export const completeJob = createAsyncThunk(
  "job/completeJob",
  async data => {
    const res = await jobAPI.completeJob(data);
    return res;
  }
);

export const getEstimate = createAsyncThunk(
  "job/getEstimate",
  async data => {
    const res = await jobAPI.getEstimate(data);
    return res;
  }
);

export const returnStatusUpdate = createAsyncThunk(
  "job/returnStatusUpdate",
  async data => {
    const res = await jobAPI.returnStatusUpdate(data);
    return res;
  }
);

export const showEstimate = createAsyncThunk(
  "auth/showEstimate",
  async options => {
    const res = await jobAPI.showEstimate(options);
    return res;
  }
);
export const clientEstimate = createAsyncThunk(
  "auth/clientEstimate",
  async options => {
    const res = await jobAPI.clientEstimate(options);
    return res;
  }
);

export const printEstimate = createAsyncThunk(
  "auth/printEstimate",
  async options => {
    const res = await jobAPI.printEstimate(options);
    return res;
  }
);

export const listComments = createAsyncThunk(
  "job/listComments",
  async options => {
    const res = await jobAPI.listComments(options);
    return res;
  }
);

export const createComment = createAsyncThunk(
  "job/createComment",
  async options => {
    const res = await jobAPI.createComment(options);
    return res;
  }
);

export const updateComment = createAsyncThunk(
  "job/updateComment",
  async options => {
    const res = await jobAPI.updateComment(options);
    return res;
  }
);

export const deleteComment = createAsyncThunk(
  "job/deleteComment",
  async options => {
    const res = await jobAPI.deleteComment(options);
    return res;
  }
);

export const updateEstimate = createAsyncThunk(
  "job/updateEstimate",
  async data => {
    const res = await jobAPI.updateEstimate(data);
    return res;
  }
);

export const deleteEstimate = createAsyncThunk(
  "job/deleteEstimate",
  async data => {
    const res = await jobAPI.deleteEstimate(data);
    return res;
  }
);

export const updateEstimateInternalInfo = createAsyncThunk(
  "job/updateEstimate",
  async data => {
    const res = await jobAPI.updateEstimateInternalInfo(
      data
    );
    return res;
  }
);

export const AssignCrew = createAsyncThunk(
  "job/AssignCrew",
  async data => {
    const res = await jobAPI.AssignCrew(data);
    return res;
  }
);

export const scheduleReturnJob = createAsyncThunk(
  "job/AssignCrew",
  async data => {
    const res = await jobAPI.scheduleReturnJob(data);
    return res;
  }
);

export const updateRequestedService = createAsyncThunk(
  "job/updateRequestedService",
  async data => {
    const res = await jobAPI.updateRequestedService(data);
    return res;
  }
);

export const changeJobStatus = createAsyncThunk(
  "job/changeJobStatus",
  async data => {
    const res = await jobAPI.changeJobStatus(data);
    return res;
  }
);

export const getEstimates = createAsyncThunk(
  "job/getEstimates",
  async data => {
    const res = await jobAPI.getEstimates(data);
    return res;
  }
);

export const markAsApproved = createAsyncThunk(
  "job/markAsApproved",
  async data => {
    const res = await jobAPI.markAsApproved(data);
    return res;
  }
);
