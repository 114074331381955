import api from "utils/api";

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:property_id/pin",
    method: "POST",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:property_id/pin/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:property_id/pin/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/pins",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:property_id/pin/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const types = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/pin/pintype/all",
    method: "GET",
    data,
    ...options,
  });
};

export const typeSettings = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/pin/pintype/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const getPrevJobs = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/property/:property_id/pin/:id/previous-jobs",
    method: "GET",
    data,
    ...options,
  });
};
