import React, { Suspense, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Loader from "components/layouts/Loader";
import BrandNav from "components/layouts/BrandNav";
import { bindActionCreators } from "@reduxjs/toolkit";
import * as _systemActions from "store/system/actions";
import { useDispatch } from "react-redux";

const PublicLayout = props => {
  const dispatch = useDispatch();

  const systemActions = useMemo(
    () => bindActionCreators(_systemActions, dispatch),
    [dispatch]
  );

  const init = async () => {
    const initSequence = [systemActions.getConfig()];
    await Promise.all(initSequence);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <header>
        <BrandNav />
      </header>
      <main>
        <Suspense fallback={<Loader />}>
          {props.children}
        </Suspense>
      </main>
      <footer></footer>
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node,
};

export default PublicLayout;
