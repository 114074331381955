/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import styles from "./Chart.module.scss";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Popper = props => {
  const { payload, label } = props;

  return (
    <div className={`${styles.Chart} chart-tooltip`}>
      <h3>{label}</h3>
      <div className="tooltip__content">
        {payload[0]?.name !== "revenue" ? (
          <div className="tooltip__row">
            <p>{`${payload[0]?.name}`}</p>
            <span className="tooltip__data tooltip__data--green">{`${payload[0]?.value}`}</span>
          </div>
        ) : (
          <div className="tooltip__row">
            <p>{`${payload[0]?.name}`}</p>
            <span className="tooltip__data tooltip__data--green">{`$${payload[0]?.value}`}</span>
          </div>
        )}

        {payload.length > 1 ? (
          <div className="tooltip__row">
            <p>{`${payload[1]?.name}`}</p>
            <span className="tooltip__data tooltip__data--blue">{`${payload[1]?.value}`}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const Chart = props => {
  const {
    variant,
    className,
    data,
    dataKey1,
    xAxisScale,
    // dateType,
    revenueMax,
  } = props;

  const formatTick = value => {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0
        ? value / Math.pow(1000, suffixNum)
        : value
      ).toPrecision(2)
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  };

  const renderYaxis = type => {
    if (type === "jobs") {
      return (
        <YAxis
          tickCount={7}
          axisLine={false}
          tickLine={false}
          tickMargin={20}
        />
      );
    } else if (type === "revenue") {
      return (
        <YAxis
          tickCount={7}
          domain={
            // dateType === "past_7days"
            //   ? [0, parseInt(revenueMax)]
            //   :
            [0, parseInt(revenueMax)]
          }
          axisLine={false}
          tickLine={false}
          tickMargin={20}
          tickFormatter={formatTick}
        />
      );
    }
  };
  return (
    <div
      className={`${styles.Chart} ${
        styles[`Chart__${variant}`]
      } ${className}`}
    >
      <ResponsiveContainer width="100%" aspect={2}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid
            // strokeDasharray="3 3"
            vertical={false}
            strokeDasharray={0}
          />
          <XAxis
            // dataKey="month"
            dataKey={xAxisScale === "day" ? "day" : "month"}
            axisLine={false}
            tickLine={false}
          />
          {renderYaxis(dataKey1)}

          <Tooltip cursor={true} content={<Popper />} />
          <Legend />

          <Line
            type=""
            dataKey={props.dataKey1}
            stroke="#389D5D"
            strokeWidth={3}
            className="chart-dot"
            activeDot={{
              r: 4,
              stroke: "#389D5D",
              strokeWidth: 5,
              width: "16px",
              height: "16px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
            dot={{ r: 0 }}
          />
          <Line
            type=""
            dataKey={props.dataKey2}
            stroke="#3E98C7"
            strokeWidth={3}
            activeDot={{
              r: 4,
              stroke: "#3E98C7",
              strokeWidth: 5,
            }}
            dot={{ r: 0 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.defaultProps = {
  variant: "default",
  className: "",
};

Chart.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  dataKey1: PropTypes.string,
  dataKey2: PropTypes.string,
};

export default Chart;
