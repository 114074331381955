/* eslint-disable no-unused-vars */
import SelectInput, {
  Option,
} from "components/utils/SelectInput";
import WeekDatePicker from "components/utils/WeekDatePicker";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import styles from "./DashboardCalendar.module.scss";
// import startOfWeek from "date-fns/startOfWeek";
// import endOfWeek from "date-fns/endOfWeek";
// import ViewEvent from "components/calendar/ViewEvent";
// import isEqual from "date-fns/isEqual";
import addDays from "date-fns/addDays";
import {
  crews,
  estimators,
  equipment,
} from "store/calendar/selectors";
import { authUser } from "store/user/selectors";
import { useSelector, useDispatch } from "react-redux";
import * as _calendarActions from "store/calendar/actions";
import { bindActionCreators } from "redux";
import format from "date-fns/format";
import isTomorrow from "date-fns/isTomorrow";
import { ReactComponent as Estimate } from "assets/icons/estimate.svg";
import { ReactComponent as Job } from "assets/icons/job.svg";
import { ReactComponent as Tree } from "assets/icons/tree.svg";
import { useLocation, useNavigate } from "react-router-dom";
import SectionLoader from "components/utils/SectionLoader";
import { isToday } from "date-fns";
import Modal from "components/utils/Modal";
import MiniViewEvent from "../MiniViewEvent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import formatAMPM from "helpers/timeFormatter";
import TextInput from "components/utils/TextInput";
import EventDatePicker from "components/utils/EventDatePicker";
import TimeInput from "components/utils/TimeInput";
import Button from "components/utils/Button";
import icons from "helpers/icons";

export const sortedEvents = all => {
  let xx = [];
  for (let i = 0; i < all.length; i++) {
    let obj = {
      date: all[i].event_date,
      events: all.filter(
        item => item.event_date === all[i].event_date
      ),
    };

    xx.push(obj);
  }
  const sorted = [
    ...new Map(
      xx.map(item => [item["date"], item])
    ).values(),
  ];
  return sorted;
};

const chooseEventIcon = item => {
  if (item.event_types_id === 1) {
    return icons.Travel;
  } else if (item.event_types_id === 2) {
    return icons.Meeting;
  } else if (item.event_types_id === 3) {
    return icons.Block;
  } else if (item.event_types_id === 4) {
    return icons.Time_off;
  } else if (item.event_types_id === 5) {
    return icons.Crew_time;
  } else if (item.event_types_id === 6) {
    return icons.Equipment;
  } else if (item.event_types_id === 8) {
    return icons.Tree;
  } else if (item.event_types_id === 9) {
    return icons.Training;
  } else if (item.event_types_id === 10) {
    return icons.Holiday;
  } else if (item.event_types_id > 6 && !item.job) {
    return icons.Estimate;
  } else if (item.event_types_id === 7) {
    return icons.Estimate;
  }
};

export const RenderDateAndEvents = props => {
  const { date, events } = props;

  const location = useLocation();

  const navigate = useNavigate();

  const [viewEventModal, setViewEventModal] =
    useState(false);

  const chooseJobIcon = status => {
    if (status < 4) {
      return <Estimate />;
    } else {
      return <Job />;
    }
  };

  const renderDateLabel = d => {
    const date = d.replace(/(..).(..).(....)/, "$3-$1-$2");
    if (isToday(new Date(date))) {
      return format(
        new Date(date),
        "d MMM • 'Today' • EEEE"
      );
    } else if (
      isTomorrow(
        new Date(date),
        new Date(new Date().toDateString())
      )
    ) {
      return format(
        new Date(date),
        "d MMM • 'Tomorrow' • EEEE"
      );
    } else {
      return format(new Date(date), "d MMM • EEEE");
    }
  };

  const handleEventClick = evt => {
    if (
      location.pathname === "/calendar" ||
      location.pathname.includes("job")
    ) {
      const start = new Date(
        `${evt.event_date} ${evt.event_start_time}`
      );
      const end = new Date(
        `${evt.event_date} ${evt.event_end_time}`
      );
      props.onSelect({ ...evt, start, end });
      return;
    }
    const jobId = evt?.job?.id;
    // if (jobId) navigate(`/job/${jobId}`)
    if (jobId) {
      navigate(`/job/${jobId}`);
    } else {
      navigate(`/calendar`);
    }
  };

  return events.length
    ? events.map((item, index) => (
        <div key={index} className="block">
          <div className="block__header">
            <h3 className="lg">
              {renderDateLabel(item?.date)}
            </h3>
          </div>
          {/* details */}
          <div className="block__details">
            {/* mapping for events */}
            {item.events.map((item, index) => {
              if (item.job) {
                // checkTime(item.event_start_time,item.event_end_time)
                return (
                  <div
                    onClick={() => handleEventClick(item)}
                    key={index}
                    className="widget"
                  >
                    <div className="widget__row widget__row--dark">
                      <div className="widget__details">
                        <span className="widget__thumb">
                          {chooseJobIcon(
                            item.job.job_status.value
                          )}
                        </span>
                        <h3>
                          {`${item.job.client.first_name} ${item.job.client.last_name}`}
                        </h3>
                      </div>
                      <div className="widget__time">
                        <span>
                          {item.event_start_time ===
                          item.event_end_time
                            ? "All Day"
                            : item.event_start_time}
                        </span>
                      </div>
                    </div>
                    <div className="widget__row widget__row--light">
                      <div className="widget__details">
                        {item.job?.property_addresses.map(
                          (item, index) => (
                            <p key={index}>{item}</p>
                          )
                        )}
                      </div>
                      <div className="widget__time">
                        <span>
                          {item.event_end_time ===
                          item.event_start_time
                            ? ""
                            : item.event_end_time}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="widget"
                    onClick={() => handleEventClick(item)}
                  >
                    <div className="widget__row widget__row--dark">
                      <div className="widget__details">
                        <span className="widget__thumb">
                          {chooseEventIcon(item)}
                        </span>
                        <h3>{item.title}</h3>
                      </div>
                      <div className="widget__time">
                        <span>
                          {item.event_start_time ===
                          item.event_end_time
                            ? "All Day"
                            : item.event_start_time}
                        </span>
                      </div>
                    </div>
                    <div className="widget__row widget__row--light">
                      <div className="widget__details">
                        <h3></h3>
                      </div>
                      <div className="widget__time">
                        <span>
                          {item.event_end_time ===
                          item.event_start_time
                            ? ""
                            : item.event_end_time}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))
    : "No events to display";
};

const eventSchema = yup
  .object({
    user: yup
      .string()
      .required(
        "Please select a calendar user from the dropdown!"
      ),
    title: yup
      .string()
      .required("Event title is required!"),
    type: yup.string(),
    eventDate: yup
      .date()
      .required("Event date is required"),
    start: yup.string().required("Start time is required!"),
    end: yup.string().required("End time is required!"),
  })
  .required();

const crewEventSchema = yup
  .object({
    user: yup.string(),
    eventDate: yup.date(),
    start: yup.string(),
    end: yup.string(),
  })
  .required();

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
const DashboardCalendar = props => {
  const { variant, className, jobDetails } = props;

  const dispatch = useDispatch();

  const calendarActions = useMemo(
    () => bindActionCreators(_calendarActions, dispatch),
    [dispatch]
  );

  const _authUser = useSelector(authUser);
  const _crews = useSelector(crews);
  const _equipment = useSelector(equipment);
  const _estimators = useSelector(estimators);
  const groups = [..._crews, ..._estimators];

  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState();
  const [events, setEvents] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [mode, setMode] = useState("VIEW");
  const [crewEventMode, setCrewEventMode] =
    useState("VIEW");
  const [crewEvents, setCrewEvents] = useState([]);
  const [crewEventModal, setCrewEventModal] =
    useState(false);
  const [eventTypeAndName, setEventTypeAndName] = useState({
    type: 1,
    name: "",
  });
  const [isEquipment, setIsEquipment] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [eqList, setEqList] = useState([]);

  useEffect(() => {
    setCheckedState(
      new Array(_equipment.length).fill(false)
    );
  }, []);

  const handleOnChange = position => {
    const updatedCheckedState = checkedState.map(
      (item, index) => (index === position ? !item : item)
    );
    setEqList([...eqList, position + 1]);
    setCheckedState(updatedCheckedState);
  };

  const createEventForm = useForm({
    resolver: yupResolver(eventSchema),
    defaultValues: {
      title: "",
      eventDate: new Date(),
      type: 1,
      start: formatAMPM(new Date()),
      end: formatAMPM(new Date()),
    },
  });

  const createCrewEventForm = useForm({
    resolver: yupResolver(crewEventSchema),
    defaultValues: {
      eventDate: new Date(),
      start: formatAMPM(new Date()),
      end: formatAMPM(new Date()),
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    // register,
    watch,
  } = createEventForm;

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = createCrewEventForm;

  const onEdit = (type, name) => {
    setEventTypeAndName({ type: type, name: name });
    if (type === 6) setIsEquipment(true);
    setAddModal(true);
    setMode("EDIT");
    // if(event.id){ setMode("VIEW")}
  };

  const onDateClick = _date => {
    setDate(_date);
  };

  const handleSelect = e => {
    if (e.target.value === "all") {
      setSelection("all");
      return;
    }
    setSelection(e.target.value);
  };

  const listEvents = async () => {
    setLoading(true);
    const options = {
      params: {
        start_date: date.toDateString(),
        end_date: addDays(date, 7).toDateString(),
        // end_date: endOfWeek.toDateString(),
        users: selection !== "all" ? selection : "",
        all_users: selection === "all" ? true : false,
        extend_jobs: true,
      },
    };

    const res = await calendarActions
      .list({ options })
      .unwrap();
    if (res.success) {
      setEvents(res.data);
    }
    setLoading(false);
  };

  const fetchCalendarSettings = async () => {
    const res = await calendarActions.fetchSettings();
    if (res.payload.success) {
      // console.log(res);
    }
  };

  const submitCrewEvent = data => {
    saveCrewEvent(data);
  };

  const saveCrewEvent = data => {
    const crewEvent = {
      ...data,
      event_date: format(
        new Date(data.eventDate),
        "MM-dd-yyyy"
      ),
      title: jobDetails.client.full_name || "no_name",
      event_types_id: 8,
      crews_id: selection,
      event_start_time: data.start,
      event_end_time: data.end,
      mode: "VIEW",
      temp_id: Math.floor(Math.random() * 999999),
    };

    if (crewEventMode === "EDIT") {
      const index = crewEvents.findIndex(
        item => item.temp_id === selectedEvent.temp_id
      );
      crewEvents.splice(index, 1, {
        ...crewEvent,
        temp_id: selectedEvent.temp_id,
      });
      setCrewEventModal(false);
      handleCloseModal();
      props.crewEventsToAssign(crewEvents);
      return;
    }
    props.crewEventsToAssign(crewEvents);
    setCrewEvents([...crewEvents, crewEvent]);
    setCrewEventModal(false);
  };

  const onSubmit = data => {
    saveEvent(data);
  };

  const saveEvent = async data => {
    const requestData = {
      title: data.title,
      event_date: format(
        new Date(data.eventDate),
        "yyyy-MM-dd"
      ),
      event_start_time: data.start,
      event_end_time: data.end,
      event_types_id: eventTypeAndName.type,
      users_id: data.user,
      equipments: eqList,
    };

    const options = {
      query: {
        id: selectedEvent.id,
      },
    };

    setLoading(true);
    let response;
    if (mode === "EDIT" && selectedEvent.id) {
      response = await calendarActions
        .update({ data: requestData, options })
        .unwrap();
    } else {
      response = await calendarActions
        .create({ data: requestData })
        .unwrap();
    }

    if (response.success) {
      setAddModal(false);
      listEvents();
    }
    setIsEquipment(false);
    setEventTypeAndName({});
    setLoading(false);
  };

  const handleViewEventClick = event_data => {
    setSelectedEvent(event_data);
    setAddModal(true);
    setMode("VIEW");
  };

  const mapEquipmentOnRender = eq => {
    const checkedStateCopy = checkedState;
    for (let i = 0; i < checkedStateCopy.length; i++) {
      for (let j = 0; j < eq.length; j++) {
        if (eq[j]) {
          let item = eq[j] - 1;
          checkedStateCopy[item] = true;
        } else return;
      }
    }
    setCheckedState(checkedStateCopy);
  };

  const handleEventEdit = event => {
    if (event.equipments.length) {
      setIsEquipment(true);
      const eqIdList = event.equipments.map(
        item => item.id
      );
      setEqList(eqIdList);
      mapEquipmentOnRender(eqIdList);
    }

    if (event.temp_id) {
      setCrewEventModal(true);
      reset2({
        user: event.crews_id,
        eventDate: event.eventDate,
        start: event.event_start_time,
        end: event.event_end_time,
      });
      setSelectedEvent(event);
      setCrewEventMode("EDIT");
      return;
    }
    setMode("EDIT");
    reset({
      user: event.user.hash,
      title: event.title,
      eventDate: event.start,
      start: event.event_start_time,
      end: event.event_end_time,
    });
  };

  const handleDeleteEvent = async evt => {
    if (evt.temp_id) {
      const newEvents = crewEvents.filter(
        item => item.temp_id !== evt.temp_id
      );
      setCrewEvents(newEvents);
      setAddModal(false);
      return;
    }
    setLoading(true);
    const options = {
      query: {
        id: evt.id,
      },
    };

    await calendarActions.remove({ options });
    await listEvents();
    setAddModal(false);
    setLoading(false);
  };

  const handleCloseModal = () => {
    setAddModal(false);
    setSelectedEvent({});
    setEventTypeAndName({});
    setIsEquipment(false);
    setCheckedState(
      new Array(_equipment.length).fill(false)
    );
  };

  const handleCloseNewcrewEventModal = () => {
    setCrewEventModal(false);
    return;
  };

  const renderAddEditEvent = () => {
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="add_edit_event_form"
      >
        <div className="event-modal">
          <h2 lassName="event_name">
            {eventTypeAndName.name}
          </h2>

          <div className="event_form_user">
            <Controller
              control={control}
              name="user"
              render={({ field }) => (
                <SelectInput
                  {...field}
                  placeholder="Calendar user"
                >
                  {groups.length
                    ? groups.map(item => {
                        return (
                          <Option
                            className="option"
                            value={item.hash}
                            key={item.id}
                          >
                            {item.calendar_name}
                          </Option>
                        );
                      })
                    : ""}
                  <Option value="all">All</Option>
                </SelectInput>
              )}
            />
          </div>

          <div className="event_form_title">
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <TextInput
                  {...field}
                  variant="standard"
                  className="input"
                  placeholder="Add title"
                  // errors={errors?.title?.message}
                />
              )}
            />
          </div>

          <div className="event-modal__input">
            <div className="event-modal__date">
              <label htmlFor="eventDate"></label>
              <Controller
                control={control}
                name="eventDate"
                render={({ field }) => (
                  <EventDatePicker {...field} />
                )}
              />
            </div>

            <div className="event-modal__time">
              <div className="time-start">
                <label htmlFor="start"></label>
                <Controller
                  control={control}
                  name="start"
                  render={({ field }) => (
                    <TimeInput {...field} />
                  )}
                />
              </div>
              <div className="separator">-</div>
              <div className="time-end">
                <label htmlFor="end"></label>
                <Controller
                  control={control}
                  name="end"
                  render={({ field }) => (
                    <TimeInput {...field} />
                  )}
                />
              </div>
            </div>
          </div>

          {/* Equipments listing */}
          <div className="event-form__equipments">
            {isEquipment && (
              <div className="equipments">
                <div className="equipments__title">
                  <h3>Select Equipment</h3>
                </div>
                <div className="equipments__list">
                  {_equipment.map((item, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={item.name}
                        value={item.name}
                        className="checkbox"
                        checked={checkedState[index]}
                        onChange={() =>
                          handleOnChange(index)
                        }
                      />
                      <label
                        htmlFor={`custom-checkbox-${index}`}
                      >
                        {item.name}
                      </label>
                    </li>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="add-edit-event-errors">
            <span>{errors?.title?.message}</span>
            <span>{errors?.user?.message}</span>
          </div>

          <div className="event-modal__btn">
            <Button
              className="btn btn--white"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className="btn"
              type="submit"
              loading={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const renderViewEvent = () => {
    return (
      <MiniViewEvent
        event={selectedEvent}
        onEdit={handleEventEdit}
        onDelete={handleDeleteEvent}
        onClose={handleCloseModal}
      />
    );
  };

  const renderAddEditCrewEvent = () => {
    return (
      <form
        onSubmit={handleSubmit2(submitCrewEvent)}
        className="add_edit_event_form"
      >
        <div className="event-modal">
          <div className="event-modal__input">
            <div className="event-modal__date">
              <label htmlFor="eventDate"></label>
              <Controller
                control={control2}
                name="eventDate"
                render={({ field }) => (
                  <EventDatePicker {...field} />
                )}
              />
            </div>

            <div className="event-modal__time">
              <div className="time-start">
                <label htmlFor="start"></label>
                <Controller
                  control={control2}
                  name="start"
                  render={({ field }) => (
                    <TimeInput {...field} />
                  )}
                />
              </div>
              <div className="separator">-</div>
              <div className="time-end">
                <label htmlFor="end"></label>
                <Controller
                  control={control2}
                  name="end"
                  render={({ field }) => (
                    <TimeInput {...field} />
                  )}
                />
              </div>
            </div>
            {/* form buttons */}
            <div className="event-modal__btn">
              <Button
                className="btn btn--white"
                onClick={handleCloseNewcrewEventModal}
              >
                Cancel
              </Button>
              <Button
                className="btn"
                type="submit"
                loading={loading}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  useEffect(() => {
    if (selectedEvent) {
      reset({
        title: "",
        eventDate: new Date(),
        type: 1,
        user: "",
        start: formatAMPM(new Date()),
        end: formatAMPM(new Date()),
      });
    }
  }, [selectedEvent]);

  useEffect(async () => {
    await fetchCalendarSettings();
    if (
      _authUser.role.slug !== "scheduling-admin" &&
      _authUser.role.slug !== "admin"
    ) {
      setSelection(_authUser.hash);
    } else if (location.pathname.includes("job")) {
      setSelection(props.crewId);
    } else {
      setSelection("all");
    }
  }, []);

  useEffect(async () => {
    await listEvents();
  }, [date, selection]);

  return (
    <div
      className={`${styles.DashboardCalendar} ${
        styles[`DashboardCalendar__${variant}`]
      } ${className}`}
    >
      <div className="dboard-cal">
        <div className="dboard-cal__header">
          <h2 className="lg">Calendar</h2>
          <SelectInput
            name="groups"
            variant="standard"
            onChange={handleSelect}
            className="dashboard-select"
            value={selection}
          >
            {groups.length
              ? groups.map(item => {
                  return (
                    <Option value={item.hash} key={item.id}>
                      {item.calendar_name}
                    </Option>
                  );
                })
              : ""}
            <Option value="all">All</Option>
          </SelectInput>
        </div>
        <div>
          <WeekDatePicker
            date={onDateClick}
            onAddNewEvent={onEdit}
            renderAddEditEventForm={renderAddEditEvent}
            setCrewEventModal={setCrewEventModal}
          />
        </div>

        <div className="dboard-cal__content">
          {loading ? (
            <SectionLoader />
          ) : (
            <RenderDateAndEvents
              events={sortedEvents([
                ...crewEvents,
                ...events,
              ])}
              onEdit={onEdit}
              onSelect={handleViewEventClick}
            />
          )}
        </div>
      </div>

      {
        <Modal
          title=""
          className={styles.MiniCalendarAddEvent}
          open={addModal}
          showCloseButton={false}
          onClose={handleCloseModal}
        >
          {mode === "EDIT" && renderAddEditEvent()}
          {mode === "VIEW" && renderViewEvent()}
        </Modal>
      }

      {
        <Modal
          title={props.crewName}
          className={styles.MiniCalendarAddEvent}
          open={crewEventModal}
          showCloseButton={false}
          onClose={handleCloseNewcrewEventModal}
        >
          {renderAddEditCrewEvent()}
        </Modal>
      }
    </div>
  );
};

DashboardCalendar.defaultProps = {
  variant: "default",
  className: "",
};

DashboardCalendar.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  crewName: PropTypes.string,
  crewId: PropTypes.string,
  jobDetails: PropTypes.object,
  crewEventsToAssign: PropTypes.func,
};

RenderDateAndEvents.propTypes = {
  date: PropTypes.object,
  events: PropTypes.array,
};

export default DashboardCalendar;
