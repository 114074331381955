import { createAsyncThunk } from "@reduxjs/toolkit";
import * as eventAPI from "api/event";

export const create = createAsyncThunk(
  "event/create",
  async data => {
    const res = await eventAPI.create(data);
    return res;
  }
);

export const get = createAsyncThunk(
  "event/get",
  async data => {
    const res = await eventAPI.get(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "event/update",
  async data => {
    const res = await eventAPI.update(data);
    return res;
  }
);

export const list = createAsyncThunk(
  "events/list",
  async data => {
    const res = await eventAPI.list(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "event/remove",
  async data => {
    const res = await eventAPI.remove(data);
    return res;
  }
);

export const fetchSettings = createAsyncThunk(
  "event/settings",
  async data => {
    const res = await eventAPI.fetchSettings(data);
    return res;
  }
);
