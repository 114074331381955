import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const clientSlice = createSlice({
  name: "client",
  initialState: {
    all: [],
    selected: {},
    notes: [],
    properties: [],
    jobs: [],
    loading: false,
  },
  extraReducers: {
    [actions.list.pending]: state => {
      state.loading = true;
    },
    [actions.list.fulfilled]: (state, action) => {
      const clients = action.payload?.data || [];
      state.all = clients;
      state.loading = false;
    },
    [actions.list.rejected]: state => {
      state.loading = false;
    },
    [actions.get.pending]: state => {
      state.loading = true;
    },
    [actions.get.fulfilled]: (state, action) => {
      const { client } = action.payload?.data || {};
      state.selected = client || {};
      state.loading = false;
    },
    [actions.get.rejected]: state => {
      state.loading = false;
    },
    [actions.createNote.pending]: state => {
      state.loading = true;
    },
    [actions.createNote.fulfilled]: (state, action) => {
      const notes = action.payload?.data || [];
      state.notes = notes;
      state.loading = false;
    },
    [actions.createNote.rejected]: state => {
      state.loading = false;
    },
    [actions.listNote.pending]: state => {
      state.loading = true;
    },
    [actions.listNote.fulfilled]: (state, action) => {
      const notes = action.payload?.data || [];
      state.notes = notes;
      state.loading = false;
    },
    [actions.listNote.rejected]: state => {
      state.loading = false;
    },
    [actions.updateNote.pending]: state => {
      state.loading = true;
    },
    [actions.updateNote.fulfilled]: (state, action) => {
      const notes = action.payload?.data || [];
      state.notes = notes;
      state.loading = false;
    },
    [actions.updateNote.rejected]: state => {
      state.loading = false;
    },
    [actions.removeNote.pending]: state => {
      state.loading = true;
    },
    [actions.removeNote.fulfilled]: (state, action) => {
      const notes = action.payload?.data || [];
      state.notes = notes;
      state.loading = false;
    },
    [actions.removeNote.rejected]: state => {
      state.loading = false;
    },
    [actions.listProperty.pending]: state => {
      state.loading = true;
    },
    [actions.listProperty.fulfilled]: (state, action) => {
      const properties = action.payload?.data || [];
      state.properties = properties;
      state.loading = false;
    },
    [actions.listProperty.rejected]: state => {
      state.loading = false;
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice.reducer;
