import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Popper.module.scss";

import { usePopper } from "react-popper";
import OutsideClickHandler from "../OutsideClickHandler";

const Popper = props => {
  const {
    variant,
    className,
    trigger: _trigger,
    open,
    onOpen,
    onClose,
    arrow,
    offset,
    placement,
  } = props;

  const [referenceElement, setReferenceElement] =
    useState(null);

  const [popperElement, setPopperElement] = useState(null);

  const [arrowElement, setArrowElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement || "bottom",
      modifiers: [
        {
          name: "arrow",
          options: { element: arrowElement },
        },
        {
          name: "offset",
          enabled: true,
          options: {
            offset: offset || [20, 10],
          },
        },
      ],
    }
  );

  const trigger = React.cloneElement(_trigger, {
    ..._trigger.props,
    onClick: open ? onClose : onOpen,
    ref: setReferenceElement,
  });

  return (
    <>
      <OutsideClickHandler onOutsideClick={onClose}>
        {trigger}
        {!!open && (
          <div
            className={`${styles.Popper} ${
              styles[`Popper__${variant}`]
            } ${className}`}
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {props.children}
            {arrow && (
              <div
                ref={setArrowElement}
                style={styles.arrow}
              />
            )}
          </div>
        )}
      </OutsideClickHandler>
    </>
  );
};

Popper.defaultProps = {
  variant: "default",
  className: "",
  arrow: false,
};

Popper.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  arrow: PropTypes.bool,
  offset: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.string,
};

export default Popper;
