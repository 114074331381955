const { createSelector } = require("@reduxjs/toolkit");

const dashboardState = state => state.dashboard;

export const events = createSelector(
  dashboardState,
  dashboard => dashboard.events
);

export const jobs = createSelector(
  dashboardState,
  dashboard => dashboard.jobs
);

export const trends = createSelector(
  dashboardState,
  dashboard => dashboard.trends.trends
);

export const sales = createSelector(
  dashboardState,
  dashboard => dashboard.sales.sales
);
