import Toast from "components/utils/Toast";
import AppRouter from "./router";

// import ErrorBoundary from "components/utils/ErrorBoundary";

function App() {
  return (
    <>
      <AppRouter />
      <Toast />
    </>
  );
}

export default App;
