import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import Spinner from "../Spinner";
/**
 * General Button component for app.
 */
function Button(props) {
  const { onClick, type, disabled, loading, form } = props;
  return (
    <button
      form={form}
      className={`${styles.Button} ${props.className}`}
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner className="btn-loader" />
      ) : (
        props.children
      )}
    </button>
  );
}

Button.defaultProps = {
  className: "",
  onClick: () => {},
  type: "button",
};

Button.propTypes = {
  form: PropTypes.string,
  /** ClassName for style modifications */
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.oneOf(["button", "submit"]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;
