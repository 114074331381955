import PropTypes from "prop-types";
import styles from "./SalesCard.module.scss";
import { ReactComponent as UpArrow } from "assets/icons/up-arrow.svg";
import { ReactComponent as DownArrow } from "assets/icons/down-arrow.svg";

const SalesCard = props => {
  const { variant, className, name, count, percent, date } =
    props;

  const renderPercentage = type => {
    if (type === "this_week") {
      return (
        <div className="sales__trend">
          <span className="sales__direction">
            {percent > 0 ? <UpArrow /> : <DownArrow />}
          </span>
          <strong
            className={percent <= 0 ? "below__zero" : ""}
          >
            {Math.abs(percent)}%
          </strong>
          <p>Since last week</p>
        </div>
      );
    } else if (type === "this_month") {
      return (
        <div className="sales__trend">
          <span className="sales__direction">
            {percent > 0 ? <UpArrow /> : <DownArrow />}
          </span>
          <strong>{Math.abs(percent)}%</strong>
          <p>Since last Month</p>
        </div>
      );
    }
  };

  return (
    <div
      className={`${styles.SalesCard} ${
        styles[`SalesCard__${variant}`]
      } ${className}`}
    >
      <h3>{name}</h3>
      <span className="sales__number">
        {name === "Revenue" ? (
          <b>{`$${count}`}</b>
        ) : (
          <b>{count}</b>
        )}
      </span>
      {renderPercentage(date.date)}
    </div>
  );
};

SalesCard.defaultProps = {
  variant: "default",
  className: "",
};

SalesCard.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  percent: PropTypes.number,
  date: PropTypes.object,
};

export default SalesCard;
