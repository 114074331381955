import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./actions";

export const systemSlice = createSlice({
  name: "system",
  initialState: {
    user_status: [],
    roles: [],
    permissions: [],
    divisions: [],
    states: [],
    loading: false,
    crew: [],
    status: {},
    service: [],
    company_details: [],
    job_estimate_status: [],
  },
  extraReducers: {
    [actions.getConfig.pending]: state => {
      state.loading = true;
    },
    [actions.getConfig.fulfilled]: (state, action) => {
      const {
        roles,
        permissions,
        divisions,
        states,
        crew_leads,
        job_status,
        pin_services,
        equipments,
        company_details,
        user_status,
        job_estimate_status,
      } = action.payload?.data || {};
      state.roles = roles || [];
      state.permissions = permissions || [];
      state.divisions = divisions || [];
      state.states = states || [];
      state.loading = false;
      state.status = job_status || {};
      state.crew = crew_leads || [];
      state.service = pin_services || [];
      state.equipments = equipments || [];
      state.company_details = company_details || [];
      state.user_status = user_status || [];
      state.job_estimate_status = job_estimate_status || [];
    },
    [actions.getConfig.rejected]: state => {
      state.loading = false;
    },
  },
});

export const systemActions = systemSlice.actions;

export default systemSlice.reducer;
