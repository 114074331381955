/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import styles from "./DashboardJobs.module.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect, useMemo, useState } from "react";
//redux
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import * as _dashboardActions from "store/dashboard/actions";
import { jobs } from "store/dashboard/selectors";
import SectionLoader from "components/utils/SectionLoader";

const RenderProgressBar = props => {
  const { percent, name, count } = props;
  return (
    <div>
      <CircularProgressbar
        value={percent}
        text={`${Number(percent).toFixed(2)}%`}
        className="progress-bar"
      />
      <h3>{name}</h3>
      <p>{count} Jobs</p>
    </div>
  );
};

const DashboardJobs = props => {
  const dispatch = useDispatch();
  const dashboardActions = useMemo(
    () => bindActionCreators(_dashboardActions, dispatch),
    [dispatch]
  );
  const _jobs = useSelector(jobs);
  const { variant, className } = props;
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState({
    names: [],
    values: [],
  });

  const listActiveJobs = async () => {
    setLoading(true);
    const res = await dashboardActions
      .getActiveJobs()
      .unwrap();
    if (res.status) {
      const obj = res.data.active_jobs;
      const keys = Object.keys(obj);
      const values = Object.values(obj);
      setAllJobs({ ...jobs, names: keys, values: values });
    }
    setLoading(false);
  };

  useEffect(() => {
    listActiveJobs();
  }, []);

  return (
    <div
      className={`${styles.DashboardJobs} ${
        styles[`DashboardJobs__${variant} job-status`]
      } ${className}`}
    >
      <h2>Active Jobs</h2>
      {loading ? (
        <SectionLoader />
      ) : (
        <div className="job-status__content">
          <div className="job-status__progress">
            <RenderProgressBar
              percent={allJobs?.values[0]?.percentage || 0}
              name="Estimate"
              count={allJobs?.values[0]?.count || 0}
            />
          </div>
          <div className="job-status__progress">
            <RenderProgressBar
              percent={allJobs?.values[1]?.percentage || 0}
              name="Pending"
              count={allJobs?.values[1]?.count || 0}
            />
          </div>
          <div className="job-status__progress">
            <RenderProgressBar
              percent={allJobs?.values[2]?.percentage || 0}
              name="In progress"
              count={allJobs?.values[2]?.count || 0}
            />
          </div>
          <div className="job-status__progress">
            <RenderProgressBar
              percent={allJobs?.values[3]?.percentage || 0}
              name="Go Back"
              count={allJobs?.values[3]?.count || 0}
            />
          </div>
          <div className="job-status__progress">
            <RenderProgressBar
              percent={allJobs?.values[4]?.percentage || 0}
              name="Complete"
              count={allJobs?.values[4]?.count || 0}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DashboardJobs.defaultProps = {
  variant: "default",
  className: "",
};

DashboardJobs.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

RenderProgressBar.propTypes = {
  percent: PropTypes.number,
  name: PropTypes.string,
  count: PropTypes.number,
};

export default DashboardJobs;
