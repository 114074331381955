import { createAsyncThunk } from "@reduxjs/toolkit";
import * as pinAPI from "api/pin";

export const create = createAsyncThunk(
  "pin/create",
  async data => {
    const res = await pinAPI.create(data);
    return res;
  }
);

export const get = createAsyncThunk(
  "pin/get",
  async data => {
    const res = await pinAPI.get(data);
    return res;
  }
);

export const update = createAsyncThunk(
  "pin/update",
  async data => {
    const res = await pinAPI.update(data);
    return res;
  }
);

export const list = createAsyncThunk(
  "pin/list",
  async data => {
    const res = await pinAPI.list(data);
    return res;
  }
);

export const remove = createAsyncThunk(
  "pin/remove",
  async data => {
    const res = await pinAPI.remove(data);
    return res;
  }
);

export const types = createAsyncThunk(
  "pin/types",
  async data => {
    const res = await pinAPI.types(data);
    return res;
  }
);

export const typeSettings = createAsyncThunk(
  "pin/typeSettings",
  async data => {
    const res = await pinAPI.typeSettings(data);
    return res;
  }
);

export const getPrevJobs = createAsyncThunk(
  "pin/getPrevJobs",
  async data => {
    const res = await pinAPI.getPrevJobs(data);
    return res;
  }
);
