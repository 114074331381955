import api from "utils/api";

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client",
    method: "POST",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/clients",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const createNote = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:client_id/note",
    method: "POST",
    data,
    ...options,
  });
};

export const updateNote = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:client_id/note/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const listNote = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:client_id/notes",
    method: "GET",
    data,
    ...options,
  });
};

export const removeNote = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:client_id/note/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const listProperty = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/client/:client_id/properties",
    method: "GET",
    data,
    ...options,
  });
};
