import SearchInput from "components/utils/SearchInput";
import SectionLoader from "components/utils/SectionLoader";
import PropTypes from "prop-types";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./Search.module.scss";
import * as _systemActions from "store/system/actions";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Client from "models/Client";
import { usePopper } from "react-popper";
import debounce from "utils/debounce";
import OutsideClickHandler from "components/utils/OutsideClickHandler";
import Job from "models/Job";
import Property from "models/Property";

const ResultItem = props => {
  const { textRenderer, type, onClick, item } = props;
  return (
    <div onClick={onClick} className="results__group">
      {type === "job" && (
        <>
          <p className="title">
            {textRenderer(
              `${item.client.first_name} ${item.client.last_name}`
            )}
          </p>
          {item.property_addresses.map((address, index) => (
            <p className="address" key={index}>
              {textRenderer(address)}
            </p>
          ))}
          <p className="status">{item.job_status.status}</p>
        </>
      )}

      {type === "client" && (
        <>
          <p className="title">
            {textRenderer(item.fullName())}
          </p>
          <p className="address">
            {textRenderer(item.address)}
          </p>
        </>
      )}

      {type === "property" && (
        <>
          <p className="title">
            {textRenderer(item.property_name)}
          </p>
          <p className="address">
            {textRenderer(item.address)}
          </p>
        </>
      )}
    </div>
  );
};

ResultItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  textRenderer: PropTypes.func,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

const Search = props => {
  const dispatch = useDispatch();

  const systemActions = useMemo(
    () => bindActionCreators(_systemActions, dispatch),
    [dispatch]
  );

  const [referenceElement, setReferenceElement] =
    useState(null);

  const [popperElement, setPopperElement] = useState(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, -23],
          },
        },
      ],
    }
  );

  const [loading, setLoading] = useState(true);

  const [keyword, setKeyword] = useState("");

  const [results, setResults] = useState({});

  const textRenderer = text => {
    const parts = text.split(
      new RegExp(`(${keyword})`, "gi")
    );
    return (
      <span>
        {parts.map(part =>
          part.toLowerCase() === keyword.toLowerCase() ? (
            <b>{part}</b>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const handleResultClick = () => {
    resetValue();
  };

  const fetchResults = async options => {
    setResults([]);
    const res = await systemActions
      .search({ options })
      .unwrap();
    if (res.success) {
      setResults(res.data);
    }
    setLoading(false);
  };

  const debounceFetchResults = useCallback(
    debounce(fetchResults),
    []
  );

  const handleSearch = e => {
    setKeyword(e.target.value);
  };

  const resetValue = () => {
    setKeyword("");
  };

  const handleOutSideClick = () => {
    setKeyword("");
  };

  useEffect(() => {
    if (!keyword) return;
    setLoading(true);
    const options = {
      params: {
        keyword: keyword || undefined,
      },
    };
    debounceFetchResults(options);
  }, [keyword]);

  return (
    <div className={`${styles.Search} ${props.className}`}>
      <OutsideClickHandler
        onOutsideClick={handleOutSideClick}
      >
        <div
          className="search-bar"
          ref={setReferenceElement}
        >
          <SearchInput
            placeholder="Search"
            value={keyword}
            onChange={handleSearch}
            resetValue={resetValue}
          />
        </div>
        {!!keyword.length && (
          <div
            className="results"
            ref={setPopperElement}
            style={popperStyles.popper}
            {...attributes.popper}
          >
            {loading ? (
              <SectionLoader className="loader" />
            ) : (
              <>
                {Object.keys(results).some(
                  key => !!results[key].length
                ) ? (
                  <>
                    {!!results.jobs?.length && (
                      <div className="results__list">
                        <h3>Jobs</h3>
                        <ul>
                          {results.jobs.map(job => {
                            return (
                              <li key={job.id}>
                                <Link to={`/job/${job.id}`}>
                                  <ResultItem
                                    type="job"
                                    item={new Job(job)}
                                    textRenderer={
                                      textRenderer
                                    }
                                    onClick={() => {
                                      handleResultClick(
                                        job
                                      );
                                    }}
                                  />
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    {!!results.clients?.length && (
                      <div className="results__list">
                        <h3>Clients</h3>
                        <ul>
                          {results.clients.map(client => {
                            return (
                              <li key={client.id}>
                                <Link
                                  to={`/client/${client.id}`}
                                >
                                  <ResultItem
                                    type="client"
                                    item={
                                      new Client(client)
                                    }
                                    textRenderer={
                                      textRenderer
                                    }
                                    onClick={() => {
                                      handleResultClick(
                                        client
                                      );
                                    }}
                                  />
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    {!!results.properties?.length && (
                      <div className="results__list">
                        <h3>Properties</h3>
                        <ul>
                          {results.properties.map(
                            property => {
                              return (
                                <li key={property.id}>
                                  <Link
                                    to={`/property/${property.id}`}
                                  >
                                    <ResultItem
                                      type="property"
                                      item={
                                        new Property(
                                          property
                                        )
                                      }
                                      textRenderer={
                                        textRenderer
                                      }
                                      onClick={() => {
                                        handleResultClick(
                                          property
                                        );
                                      }}
                                    />
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="no-results">
                    No results found
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

Search.defaultProps = {
  className: "",
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
