import PropTypes from "prop-types";
import styles from "./DashboardNotification.module.scss";
import Notifications from "components/users/Notifications";

const DashboardNotification = props => {
  const { variant, className } = props;
  return (
    <div
      className={`${styles.DashboardNotification} ${
        styles[`DashboardNotification__${variant}`]
      } ${className}`}
    >
      <Notifications className="dboard__notification" />
    </div>
  );
};

DashboardNotification.defaultProps = {
  variant: "default",
  className: "",
};

DashboardNotification.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardNotification;
