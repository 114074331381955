import PropTypes from "prop-types";
import styles from "./Modal.module.scss";
import ReactModal from "react-modal";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

ReactModal.setAppElement("#root");

const Modal = props => {
  const { className, title, size } = props;
  const sizeClass = size ? `size-${size}` : "";
  return (
    <ReactModal
      isOpen={props.open}
      onRequestClose={props.onClose}
      className={`${styles.Modal} ${sizeClass} ${className}`}
      overlayClassName={styles.ModalOverlay}
      shouldCloseOnOverlayClick={true}
    >
      <div className="header">
        <h2 className="title">{title}</h2>
        {!!props.onClose && (
          <CloseIcon
            className="close"
            onClick={props.onClose}
          />
        )}
      </div>
      {props.children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  className: "",
  open: false,
};

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

export default Modal;
