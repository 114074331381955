import Pusher from "pusher-js";
import { authorizeSocket } from "api/user";

//refer https://www.npmjs.com/package/pusher-js
let authorizer = channel => {
  return {
    authorize: async (socketId, callback) => {
      const requestData = {
        channel_name: channel.name,
        socket_id: socketId,
      };

      const res = await authorizeSocket({
        data: requestData,
      });
      if (res.auth) {
        callback(null, res);
      } else {
        callback(new Error("something went wrong"));
      }
    },
  };
};

const pusher = new Pusher(
  process.env.REACT_APP_PUSHER_APP_KEY,
  {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    appId: process.env.REACT_APP_PUSHER_APP_ID,
    secret: process.env.REACT_APP_PUSHER_SECRET,
    encrypted: true,
    useTLS: true,
    authorizer: authorizer,
  }
);

export default pusher;
