import api from "utils/api";

export const create = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/event",
    method: "POST",
    data,
    ...options,
  });
};

export const get = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/event/:id",
    method: "GET",
    data,
    ...options,
  });
};

export const update = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/event/:id",
    method: "PATCH",
    data,
    ...options,
  });
};

export const list = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/events",
    method: "GET",
    data,
    ...options,
  });
};

export const remove = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/event/:id",
    method: "DELETE",
    data,
    ...options,
  });
};

export const fetchSettings = (config = {}) => {
  const { data, options } = config;
  return api({
    url: "/v1/calendar/settings",
    method: "GET",
    data,
    ...options,
  });
};
