import Event from "models/Event";

const { createSelector } = require("@reduxjs/toolkit");

const calendarState = state => state.calendar; //"name" of slice

export const allEvents = createSelector(
  calendarState,
  calendar =>
    calendar.allEvents.map(_event => new Event(_event))
);

export const updatedEvent = createSelector(
  calendarState,
  calendar => calendar.selectedEvent
);

export const estimators = createSelector(
  calendarState,
  calendar => calendar.estimators
);

export const eventTypes = createSelector(
  calendarState,
  calendar => calendar.eventTypes
);

export const crews = createSelector(
  calendarState,
  calendar => calendar.crews
);

export const equipment = createSelector(
  calendarState,
  calendar => calendar?.equipment
);

export const actionedEvent = createSelector(
  calendarState,
  calendar => calendar.actionedEvent
);
